import { RefObject } from 'react'
import { Card, Icon } from '@/components'

import styles from './Card.module.scss'

interface IPaymentInAppCard {
  iosRef: RefObject<HTMLAnchorElement>
  playMarketRef: RefObject<HTMLAnchorElement>
}

export default function PaymentInAppCard({ playMarketRef, iosRef }: IPaymentInAppCard) {
  return (
    <Card className={styles.paymentHPApp}>
      <div className={styles.content}>
        <header>
          <h3 className={styles.subtitle}>Оплатить через приложение HippoParking</h3>
        </header>
        <section className={styles.mobileApps}>
          <a ref={iosRef} target="_blank" rel="noreferrer noopener">
            <Icon name="app-store-icon" />
          </a>
          <a ref={playMarketRef} target="_blank" rel="noreferrer noopener">
            <Icon name="google-play-icon" />
          </a>
        </section>
      </div>
      <div className={styles.image}>
        <Icon name="hp-card" />
      </div>
    </Card>
  )
}
