import * as React from 'react'
const Svg = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.26745"
      y="1.25659"
      width="63.2329"
      height="63.2329"
      rx="6.98841"
      stroke="white"
      stroke-width="2.02318"
    />
    <g clip-path="url(#clip0_145_13)">
      <path
        d="M48.8628 34.9434L42.612 38.4811L37.0055 32.9097L42.6566 27.2937L48.8628 30.8059C49.2291 31.013 49.5338 31.3137 49.7458 31.6772C49.9579 32.0406 50.0696 32.4539 50.0696 32.8746C50.0696 33.2954 49.9579 33.7087 49.7458 34.0721C49.5338 34.4356 49.2291 34.7363 48.8628 34.9434ZM15.8764 15.2067C15.7586 15.4942 15.6979 15.802 15.6978 16.1127V49.635C15.6978 49.9811 15.7695 50.3033 15.8955 50.592L33.6879 32.9081L15.8764 15.2067ZM35.3467 31.2605L40.5432 26.0958L19.2467 14.0439C18.79 13.7821 18.2585 13.6815 17.7378 13.7584L35.3467 31.2605ZM35.3467 34.5574L17.8016 51.9956C18.2769 52.0531 18.7777 51.9701 19.2467 51.7037L40.4986 39.6774L35.3467 34.5574Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_145_13">
        <rect
          width="38.2803"
          height="38.2803"
          fill="white"
          transform="translate(13.7441 13.7329)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default Svg
