import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '21',
  height = '21',
  viewBox = '0 0 21 21',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.833.667H7.5L9.833 6.5 6.917 8.25a12.833 12.833 0 0 0 5.833 5.833l1.75-2.916 5.833 2.333v4.667A2.333 2.333 0 0 1 18 20.5 18.667 18.667 0 0 1 .5 3 2.333 2.333 0 0 1 2.833.667Z" />
  </svg>
)

export default SVG
