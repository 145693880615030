import * as React from 'react'
const Svg = () => (
  <svg
    width="223"
    height="221"
    viewBox="0 0 223 221"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_36_187"
      maskUnits="userSpaceOnUse"
      x="0"
      y="-44"
      width="309"
      height="309"
    >
      <g clip-path="url(#clip0_36_187)">
        <path
          d="M172.233 -2.60864L75.006 28.528C66.9514 31.1074 62.5129 39.728 65.0924 47.7826L118.025 213.069C120.604 221.124 129.225 225.562 137.279 222.983L234.507 191.846C242.561 189.267 247 180.646 244.42 172.592L191.488 7.30499C188.909 -0.749599 180.288 -5.18808 172.233 -2.60864Z"
          stroke="white"
          stroke-width="13.3786"
        />
        <path
          d="M123.238 45.2416L142.683 39.0142M157.106 184.474L195.997 172.019"
          stroke="white"
          stroke-width="13.3786"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M162.571 86.3508L162.31 85.5664C162.001 84.6387 162.076 83.6255 162.517 82.7491C162.958 81.8726 163.73 81.2045 164.664 80.8911L155.202 84.0392C154.266 84.3506 153.492 85.0184 153.048 85.8958C152.605 86.7732 152.53 87.7882 152.839 88.7176L155.801 97.619C156.098 98.5106 156.041 99.4829 155.642 100.337C155.243 101.192 154.531 101.863 153.652 102.216L162.905 99.1368C163.369 98.9825 163.798 98.7389 164.168 98.4199C164.538 98.1009 164.841 97.7129 165.061 97.2779C165.28 96.843 165.411 96.3697 165.446 95.8851C165.482 95.4005 165.421 94.9141 165.267 94.4537L162.571 86.3508Z"
          fill="white"
        />
        <path
          d="M132.551 88.367L138.803 107.156L148.264 104.008L142.012 85.2189C141.703 84.2896 141.035 83.522 140.154 83.085C139.274 82.6481 138.253 82.5775 137.317 82.889L127.856 86.037C128.792 85.7256 129.812 85.7961 130.693 86.233C131.573 86.67 132.242 87.4376 132.551 88.367Z"
          fill="white"
        />
        <path
          d="M181.538 129.255L172.806 103.011C172.653 102.55 172.409 102.123 172.089 101.757C171.769 101.39 171.379 101.09 170.941 100.874C170.504 100.658 170.027 100.53 169.539 100.498C169.051 100.465 168.561 100.529 168.097 100.686L136.165 111.31C136.629 111.153 137.12 111.089 137.608 111.12C138.097 111.152 138.574 111.28 139.012 111.496C139.45 111.712 139.841 112.012 140.161 112.379C140.481 112.746 140.725 113.172 140.879 113.634L145.954 128.886C146.263 129.816 146.931 130.583 147.812 131.02C148.692 131.457 149.713 131.528 150.649 131.216L159.892 128.141C158.978 128.384 158.006 128.272 157.175 127.827C156.344 127.382 155.716 126.639 155.418 125.748L153.98 121.426L150.604 111.28C149.946 109.412 148.694 108.175 146.787 108.809L159.792 104.482C161.741 103.834 163.015 105.152 163.659 107.088L172.075 132.383C172.385 133.312 173.053 134.08 173.933 134.516C174.814 134.953 175.834 135.024 176.77 134.713L186.019 131.635C185.105 131.882 184.133 131.773 183.3 131.331C182.467 130.888 181.837 130.146 181.538 129.255Z"
          fill="white"
        />
      </g>
    </mask>
    <g mask="url(#mask0_36_187)">
      <rect x="1" y="-44" width="308" height="308" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_36_187">
        <rect
          width="245.02"
          height="245.02"
          fill="white"
          transform="translate(0.719727 30.8781) rotate(-17.7574)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default Svg
