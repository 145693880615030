import { useClickOutside } from '@/helpers/hooks'
import { useState, useRef } from 'react'
import { getLabelText } from '../utils'
import cn from 'classnames'
import Arrow from '@/assets/icons/Arrow'
import styles from '../SubscriptionFieldsPage.module.scss'

interface SelectProps {
  fieldName: string
  options: { title: string; value: string }[]
  value: string
  onSelectChange: (value: string) => void
  isRequired: boolean
  isDisabled: boolean
}

const CustomSelect = ({
  options,
  value,
  onSelectChange,
  isRequired,
  fieldName,
  isDisabled,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef<HTMLDivElement | null>(null)
  const label = `${getLabelText(fieldName)} ${isRequired ? '*' : ''}`

  const toggleSelect = () => {
    !isDisabled && setIsOpen(!isOpen)
  }

  const onClickOutside = () => {
    setIsOpen(false)
  }

  useClickOutside(selectRef, onClickOutside)

  const chooseOption = (option: string) => () => {
    onSelectChange(option)
    toggleSelect()
  }

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.selectWrapper}>
        <div className={styles.label}>{label}</div>
        <div ref={selectRef}>
          <div
            className={cn(styles.select, {
              [styles.select_isOpen]: isOpen,
              [styles.select_isDisabled]: isDisabled,
            })}
            onClick={toggleSelect}
          >
            <span>{!!value ? options?.find(i => i.value === value)?.title : ''}</span>
            <Arrow fill={'#222B45'} />
          </div>
          <div className={styles.option__wrapper}>
            <div className={cn(styles.option, { [styles.option_isOpen]: isOpen })}>
              <div className={styles.option__inner}>
                {options.map(option => (
                  <div
                    className={cn(styles.option__item, {
                      [styles.option__activeItem]: option.value === value,
                    })}
                    onClick={chooseOption(option.value)}
                    key={option.value}
                  >
                    {option.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomSelect
