import { ChangeEvent } from 'react'
import { FormError, Input } from '@/components'
import { getLabelText } from '../utils'
import styles from '../SubscriptionFieldsPage.module.scss'

type CustomInputProps = {
  fieldName: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  isRequired: boolean
  value: string
  error: string | null
  danger: boolean
  isDisabled: boolean
}

const CustomInput = (props: CustomInputProps) => {
  const { fieldName, onChange, isRequired, value, error, danger, isDisabled} = props
  const label = `${getLabelText(fieldName)} ${isRequired ? '*' : ''}`

  return (
    <div className={styles.inputWrapper}>
      <FormError error={error ? error : undefined} className={styles.inputError} key={fieldName}>
        <span className={styles.label}>{label}</span>
        <Input
          className={styles.input}
          value={value}
          onChange={onChange}
          danger={danger}
          disabled={isDisabled}
        />
      </FormError>
    </div>
  )
}

export default CustomInput
