import { Header } from '@/components'
import { LayoutProps } from './interfaces'
import styles from './Layout.module.scss'

export default function Layout({ headerLayout, children }: LayoutProps) {
  const withHeader = headerLayout !== 'empty'

  return (
    <div className={styles.wrap}>
      {withHeader && <Header layout={headerLayout} isPaymentPage={headerLayout === 'logo'} />}
      <main className={styles.content}>{children}</main>
    </div>
  )
}
