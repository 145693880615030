import * as React from 'react'
const Svg = () => (
  <svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="90" height="90" rx="17" fill="#3879F0" />
    <rect x="8" y="15" width="75" height="75" rx="16" fill="#3879F0" />
    <ellipse cx="45.7619" cy="52.5" rx="22.2619" ry="22" fill="#3879F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.3244 70.7936C56.7992 73.7904 51.6588 75.842 45.7285 75.842C39.7088 75.842 34.5032 73.7279 32.0202 70.6575V62.8668H31.7539C34.1161 59.6095 39.4843 57.335 45.7285 57.335C51.9727 57.335 57.341 59.6095 59.7031 62.8668H59.3244V70.7936Z"
      fill="#A6CBDF"
    />
    <path
      d="M35.9528 75.4586C35.9109 74.5667 36.0203 73.1311 36.0427 72.8838L38.6271 73.6821L41.2564 74.4017C42.1553 74.7203 43.9576 75.4203 43.9756 75.6722C43.9981 75.987 43.8745 77.7185 43.8183 78.0896C43.7519 78.5281 43.616 78.5056 43.2452 78.618C42.8744 78.7305 40.5373 78.8879 40.2901 78.8879C40.0429 78.8879 38.8968 78.8766 38.1215 78.8204C37.3462 78.7642 36.9754 78.663 36.5484 78.3707C36.1214 78.0783 35.9978 76.4143 35.9528 75.4586Z"
      fill="#A6CBDF"
    />
    <path
      d="M55.5742 75.4586C55.6163 74.5667 55.5068 73.1311 55.4844 72.8838L52.9 73.6821L50.2707 74.4017C49.3718 74.7203 47.5695 75.4203 47.5515 75.6722C47.5291 75.987 47.6527 77.7185 47.7088 78.0896C47.7752 78.5281 47.9111 78.5056 48.2819 78.618C48.6527 78.7305 50.9898 78.8879 51.237 78.8879C51.4842 78.8879 52.6303 78.8766 53.4056 78.8204C54.1809 78.7642 54.5517 78.663 54.9787 78.3707C55.4057 78.0783 55.5293 76.4143 55.5742 75.4586Z"
      fill="#A6CBDF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.7595 78.8554C38.5365 78.8464 38.3167 78.8349 38.1206 78.8207C37.3453 78.7645 36.9745 78.6633 36.5475 78.371C36.5068 78.3431 36.4689 78.3028 36.4336 78.252C36.6319 77.7301 37.0512 77.3703 37.5362 77.3703C38.1922 77.3703 38.7277 78.0283 38.7595 78.8554ZM41.25 78.8313C40.7768 78.8655 40.3829 78.8882 40.2892 78.8882C40.107 78.8882 39.4372 78.8821 38.7871 78.8565C38.8363 78.0501 39.3692 77.4153 40.0194 77.4153C40.6629 77.4153 41.1915 78.037 41.25 78.8313ZM43.6938 78.4328C43.6234 78.5111 43.5225 78.5394 43.3735 78.581C43.334 78.5923 43.291 78.6042 43.2443 78.6183C43.0168 78.6874 42.0492 78.7733 41.2781 78.8293C41.278 78.8189 41.2779 78.8086 41.2779 78.7983C41.2779 77.9352 41.8262 77.2354 42.5027 77.2354C43.0805 77.2354 43.5649 77.746 43.6938 78.4328Z"
      fill="#78A0B8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.7663 78.8554C52.9893 78.8464 53.209 78.8349 53.4052 78.8207C54.1805 78.7645 54.5513 78.6633 54.9783 78.371C55.019 78.3431 55.0567 78.3028 55.0922 78.252C54.8938 77.7301 54.4747 77.3703 53.9895 77.3703C53.3335 77.3703 52.798 78.0283 52.7663 78.8554ZM50.2757 78.8313C50.749 78.8655 51.1429 78.8882 51.2366 78.8882C51.4187 78.8882 52.0886 78.8821 52.7387 78.8565C52.6895 78.0501 52.1567 77.4153 51.5063 77.4153C50.8629 77.4153 50.3343 78.037 50.2757 78.8313ZM47.832 78.4328C47.9024 78.5111 48.0032 78.5394 48.1522 78.581H48.1523C48.1918 78.5923 48.2348 78.6042 48.2815 78.6183C48.509 78.6874 49.4766 78.7733 50.2476 78.8293C50.2478 78.8189 50.2479 78.8086 50.2479 78.7983C50.2479 77.9352 49.6996 77.2354 49.0231 77.2354C48.4453 77.2354 47.961 77.746 47.832 78.4328Z"
      fill="#78A0B8"
    />
    <path
      d="M37.8247 29.2278C38.0314 29.4797 38.5626 29.7525 38.8022 29.8574C39.5476 29.4902 41.0405 28.7309 41.0495 28.6319C41.0607 28.5082 41.0944 28.0472 41.072 27.575C41.0495 27.1028 40.9034 26.7318 40.6675 26.4394C40.4315 26.1471 39.9034 26.1133 39.7685 26.1021C39.6337 26.0908 39.2067 26.1021 38.4427 26.4844C37.6786 26.8667 37.4314 27.62 37.3752 28.0472C37.3191 28.4745 37.5663 28.913 37.8247 29.2278Z"
      fill="#82AEC8"
    />
    <path
      d="M53.2081 29.2278C53.0013 29.4797 52.4701 29.7525 52.2305 29.8574C51.4851 29.4902 49.9921 28.7309 49.9832 28.6319C49.9719 28.5082 49.9382 28.0472 49.9607 27.575C49.9832 27.1028 50.1292 26.7318 50.3652 26.4394C50.6012 26.1471 51.1293 26.1133 51.2642 26.1021C51.399 26.0908 51.826 26.1021 52.5901 26.4844C53.3541 26.8667 53.6013 27.62 53.6575 28.0472C53.7137 28.4745 53.4665 28.913 53.2081 29.2278Z"
      fill="#82AEC8"
    />
    <path
      d="M38.8184 29.0236C38.9083 29.1406 39.2004 29.3609 39.3605 29.4631L40.2707 28.9683C40.2707 28.7772 40.2341 28.2937 40.0881 27.8654C39.956 27.4781 39.7959 27.3636 39.6723 27.3482C39.4588 27.3216 39.1935 27.4497 38.9195 27.6293C38.5936 27.8429 38.5567 28.1354 38.5403 28.2937C38.5098 28.5879 38.706 28.8775 38.8184 29.0236Z"
      fill="#A6CBDF"
    />
    <path
      d="M52.2158 29.0236C52.126 29.1406 51.8338 29.3609 51.6738 29.4631L50.7637 28.9683C50.7637 28.7772 50.8001 28.2937 50.9461 27.8654C51.0783 27.4781 51.2383 27.3636 51.3619 27.3482C51.5754 27.3216 51.8408 27.4497 52.1147 27.6293C52.4406 27.8429 52.4777 28.1354 52.4941 28.2937C52.5246 28.5879 52.3282 28.8775 52.2158 29.0236Z"
      fill="#A6CBDF"
    />
    <path
      d="M56.4253 38.6698C56.4253 45.0449 49.3914 49.5313 45.4924 49.5313C40.6384 49.5313 34.9251 46.3043 34.6382 38.6699C34.391 32.0924 39.1102 27.8086 45.4924 27.8086C52.4477 27.8086 56.5826 32.6545 56.4253 38.6698Z"
      fill="#82AEC8"
    />
    <path
      d="M54.8306 44.8311H36.7177C33.6397 44.8311 31.1445 47.3279 31.1445 50.4079V67.768C31.1445 70.848 33.6397 73.3448 36.7177 73.3448H54.8306C57.9086 73.3448 60.4038 70.848 60.4038 67.768V50.4079C60.4038 47.3279 57.9086 44.8311 54.8306 44.8311Z"
      fill="white"
    />
    <path
      d="M54.8293 47.4629H36.6714C35.058 47.4629 33.75 48.7717 33.75 50.3862V67.7913C33.75 69.4059 35.058 70.7147 36.6714 70.7147H54.8293C56.4427 70.7147 57.7507 69.4059 57.7507 67.7913V50.3862C57.7507 48.7717 56.4427 47.4629 54.8293 47.4629Z"
      fill="#BDBDBD"
    />
    <path
      d="M54.8293 47.4629H36.6714C35.058 47.4629 33.75 48.7717 33.75 50.3862V67.7913C33.75 69.4059 35.058 70.7147 36.6714 70.7147H54.8293C56.4427 70.7147 57.7507 69.4059 57.7507 67.7913V50.3862C57.7507 48.7717 56.4427 47.4629 54.8293 47.4629Z"
      fill="url(#paint0_linear_19_13692)"
    />
    <path
      d="M46.2719 62.7008C45.7196 62.7008 45.2719 63.1485 45.2719 63.7008V65.2497C45.2719 65.802 44.8242 66.2497 44.2719 66.2497H43.752C43.1997 66.2497 42.752 65.802 42.752 65.2497V56.79C42.752 56.2378 43.1997 55.79 43.752 55.79H46.9231C47.7224 55.79 48.4283 55.9383 49.041 56.2353C49.6584 56.5275 50.1346 56.9466 50.4696 57.4925C50.8094 58.0337 50.9794 58.6491 50.9794 59.3388C50.9794 60.3589 50.6132 61.1755 49.8809 61.7885C49.1534 62.3967 48.1531 62.7008 46.88 62.7008H46.2719ZM45.2719 59.754C45.2719 60.3063 45.7196 60.754 46.2719 60.754H46.9231C47.4113 60.754 47.7822 60.6319 48.0359 60.3877C48.2943 60.1434 48.4235 59.7985 48.4235 59.3532C48.4235 58.8646 48.2919 58.4742 48.0287 58.1822C47.7655 57.89 47.4065 57.7415 46.9518 57.7368H46.2719C45.7196 57.7368 45.2719 58.1845 45.2719 58.7368V59.754Z"
      fill="white"
    />
    <path
      d="M45.9181 52.5435C51.8197 52.5435 56.6038 50.3739 56.6038 47.6975C56.6038 45.0212 51.8197 42.8516 45.9181 42.8516C40.0166 42.8516 35.2324 45.0212 35.2324 47.6975C35.2324 50.3739 40.0166 52.5435 45.9181 52.5435Z"
      fill="#82AEC8"
    />
    <path
      d="M43.1012 48.8938L40.5492 48.4954C40.4757 48.4839 40.4067 48.5343 40.3952 48.6079L40.0187 51.0231C40.0072 51.0967 40.0576 51.1657 40.1311 51.1772L42.6831 51.5756C42.7567 51.5871 42.8256 51.5367 42.8371 51.4631L43.2136 49.0479C43.2251 48.9743 43.1748 48.9053 43.1012 48.8938Z"
      fill="white"
    />
    <path
      d="M48.4008 48.8938L50.9527 48.4954C51.0263 48.4839 51.0952 48.5343 51.1067 48.6079L51.4833 51.0231C51.4947 51.0967 51.4444 51.1657 51.3708 51.1772L48.8189 51.5756C48.7453 51.5871 48.6763 51.5367 48.6649 51.4631L48.2883 49.0479C48.2769 48.9743 48.3272 48.9053 48.4008 48.8938Z"
      fill="white"
    />
    <path
      d="M42.6714 37.6588C43.2051 37.6588 43.6377 36.9893 43.6377 36.1634C43.6377 35.3375 43.2051 34.668 42.6714 34.668C42.1377 34.668 41.7051 35.3375 41.7051 36.1634C41.7051 36.9893 42.1377 37.6588 42.6714 37.6588Z"
      fill="white"
    />
    <path
      d="M48.8286 37.6588C48.2949 37.6588 47.8623 36.9893 47.8623 36.1634C47.8623 35.3375 48.2949 34.668 48.8286 34.668C49.3623 34.668 49.7949 35.3375 49.7949 36.1634C49.7949 36.9893 49.3623 37.6588 48.8286 37.6588Z"
      fill="white"
    />
    <path
      d="M42.8743 37.2766C43.2094 37.2766 43.4811 36.8538 43.4811 36.3322C43.4811 35.8105 43.2094 35.3877 42.8743 35.3877C42.5392 35.3877 42.2676 35.8105 42.2676 36.3322C42.2676 36.8538 42.5392 37.2766 42.8743 37.2766Z"
      fill="#787B84"
    />
    <path
      d="M48.6276 37.2766C48.2925 37.2766 48.0209 36.8538 48.0209 36.3322C48.0209 35.8105 48.2925 35.3877 48.6276 35.3877C48.9627 35.3877 49.2344 35.8105 49.2344 36.3322C49.2344 36.8538 48.9627 37.2766 48.6276 37.2766Z"
      fill="#787B84"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.7301 37.0957H43.4038C42.4368 37.0957 40.1709 37.4889 38.7146 38.4438C37.2582 39.3987 35.8941 40.7741 34.9504 42.8412C34.043 44.8287 34.1432 46.6767 34.173 47.2255C34.1742 47.2474 34.1753 47.2673 34.1762 47.285C34.221 48.1707 34.456 48.969 35.9355 49.3409C37.6841 49.7805 41.3339 50.3825 45.7224 50.3857C45.7249 50.3857 45.7275 50.3857 45.7301 50.3857C45.7346 50.3857 45.7392 50.3857 45.7437 50.3857C45.7482 50.3857 45.7528 50.3857 45.7573 50.3857C45.7599 50.3857 45.7624 50.3857 45.765 50.3857C50.1535 50.3825 53.8032 49.7805 55.5518 49.3409C57.0314 48.969 57.2665 48.1707 57.3112 47.285C57.3121 47.2673 57.3132 47.2474 57.3143 47.2255C57.3442 46.6767 57.4444 44.8287 56.537 42.8412C55.5934 40.7741 54.2293 39.3987 52.7728 38.4438C51.3164 37.4889 49.0506 37.0957 48.0836 37.0957H45.7573H45.7301Z"
      fill="#A6CBDF"
    />
    <path
      d="M37.2904 39.671C37.3264 39.716 37.5376 39.8996 37.6388 39.9858L39.3467 40.1882L40.7962 38.8165C40.7175 38.5541 40.549 38.0047 40.504 37.9057C40.4478 37.782 40.2709 37.6154 40.0321 37.456C39.7624 37.276 39.4478 37.085 38.9197 37.085C38.4926 37.0849 38.032 37.3547 37.6949 37.6809C37.2455 38.1157 37.1556 38.5916 37.1219 38.9514C37.0882 39.3112 37.2455 39.6148 37.2904 39.671Z"
      fill="#A6CBDF"
    />
    <path
      d="M54.2225 39.671C54.1866 39.716 53.9753 39.8996 53.8742 39.9858L52.1663 40.1882L50.7168 38.8165C50.7955 38.5541 50.964 38.0047 51.0089 37.9057C51.0651 37.782 51.242 37.6154 51.4809 37.456C51.7505 37.276 52.0652 37.085 52.5933 37.085C53.0205 37.0849 53.4809 37.3547 53.818 37.6809C54.2675 38.1157 54.3574 38.5916 54.3911 38.9514C54.4248 39.3112 54.2675 39.6148 54.2225 39.671Z"
      fill="#A6CBDF"
    />
    <path
      d="M38.8519 39.6702C38.9778 39.7512 39.2082 39.817 39.3131 39.8508C39.4592 39.6933 39.7738 39.2542 39.7738 39.03C39.7738 38.8838 39.6048 38.7145 39.5266 38.6477C39.4783 38.6064 39.234 38.4453 39.0097 38.4453C38.8071 38.4453 38.6838 38.5802 38.6389 38.6477C38.594 38.7152 38.5059 38.827 38.4929 39.0749C38.4787 39.3442 38.6946 39.5691 38.8519 39.6702Z"
      fill="#82AEC8"
    />
    <path
      d="M52.6601 39.6702C52.5343 39.7512 52.3037 39.817 52.199 39.8508C52.0529 39.6933 51.7383 39.2542 51.7383 39.03C51.7383 38.8838 51.9073 38.7145 51.9855 38.6477C52.0338 38.6064 52.2781 38.4453 52.5024 38.4453C52.7048 38.4453 52.8282 38.5802 52.8732 38.6477C52.9181 38.7152 53.0062 38.827 53.0192 39.0749C53.0334 39.3442 52.8174 39.5691 52.6601 39.6702Z"
      fill="#82AEC8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.6722 60.3136C29.0516 60.6262 29.7065 60.7296 30.4363 60.7296C31.5038 60.7296 32.4251 60.5639 32.7173 60.1366C33.0094 59.7094 33.1106 59.3383 33.1667 58.5625C33.2229 57.7867 33.2342 56.6399 33.2342 56.3925C33.2342 56.1451 33.0769 53.8065 32.9645 53.4354C32.9503 53.3889 32.9383 53.3457 32.9273 53.3061C32.8505 53.0313 32.8194 52.9202 32.4364 52.862C32.0656 52.8058 31.1791 52.6904 30.7172 52.6904C30.1162 52.6904 29.4722 52.7012 28.8857 52.8366C28.3913 52.9265 28.3446 53.0053 28.2902 53.2639C28.2452 53.4775 27.998 54.9167 27.998 56.0748C27.998 56.316 27.977 56.5486 27.9756 56.7719C27.9702 57.6207 27.9854 58.3309 28.0655 58.8294C28.1314 59.2403 28.207 59.6365 28.3139 59.9001C28.4071 60.073 28.5048 60.2021 28.6722 60.3136Z"
      fill="#A6CBDF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M62.8529 60.3136C62.4736 60.6262 61.8188 60.7296 61.0888 60.7296C60.0214 60.7296 59.1 60.5639 58.8079 60.1366C58.5157 59.7094 58.4146 59.3383 58.3584 58.5625C58.3023 57.7867 58.291 56.6399 58.291 56.3925C58.291 56.1451 58.4483 53.8065 58.5607 53.4354C58.5748 53.3889 58.5868 53.3457 58.598 53.3061C58.6746 53.0313 58.7059 52.9202 59.0888 52.862C59.4596 52.8058 60.3461 52.6904 60.8079 52.6904C61.4089 52.6904 62.0529 52.7012 62.6395 52.8366C63.1339 52.9265 63.1806 53.0053 63.235 53.2639C63.2799 53.4775 63.5271 54.9167 63.5271 56.0748C63.5271 56.316 63.5482 56.5486 63.5496 56.7719C63.555 57.6207 63.5397 58.3309 63.4597 58.8294C63.3936 59.2403 63.3181 59.6365 63.2112 59.9001C63.1181 60.073 63.0204 60.2021 62.8529 60.3136Z"
      fill="#A6CBDF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.2009 57.9229C33.1919 58.1459 33.1805 58.3659 33.1663 58.5622C33.1102 59.338 33.009 59.709 32.7169 60.1363C32.6891 60.177 32.6489 60.2148 32.598 60.2503C32.0765 60.0517 31.7169 59.6324 31.7169 59.1469C31.7169 58.4905 32.3744 57.9546 33.2009 57.9229ZM33.177 55.4306C33.2112 55.9042 33.2338 56.2984 33.2338 56.3922C33.2338 56.5743 33.2277 57.2447 33.202 57.8952C32.3963 57.846 31.7618 57.3128 31.7618 56.662C31.7618 56.0182 32.3832 55.4891 33.177 55.4306ZM32.7788 52.9854C32.857 53.0557 32.8852 53.1567 32.9269 53.3058H32.9269C32.9379 53.3454 32.95 53.3883 32.9641 53.4351C33.033 53.6627 33.1189 54.631 33.175 55.4025C33.1646 55.4027 33.1543 55.4027 33.1439 55.4027C32.2813 55.4027 31.582 54.854 31.582 54.1772C31.582 53.599 32.0923 53.1144 32.7788 52.9854Z"
      fill="#78A0B8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.3238 57.9229C58.3328 58.1459 58.3443 58.3659 58.3584 58.5622C58.4146 59.338 58.5157 59.709 58.8079 60.1363C58.8357 60.177 58.876 60.2148 58.9268 60.2503C59.4484 60.0517 59.8079 59.6324 59.8079 59.1469C59.8079 58.4905 59.1504 57.9546 58.3238 57.9229ZM58.3479 55.4306C58.3137 55.9042 58.291 56.2984 58.291 56.3922C58.291 56.5743 58.2971 57.2447 58.3227 57.8952C59.1286 57.846 59.763 57.3128 59.763 56.662C59.763 56.0182 59.1416 55.4891 58.3479 55.4306ZM58.7461 52.9854C58.6679 53.0557 58.6396 53.1567 58.598 53.3058C58.5868 53.3454 58.5748 53.3883 58.5607 53.4351C58.4917 53.6627 58.4059 54.631 58.3499 55.4025C58.3602 55.4027 58.3706 55.4027 58.3809 55.4027C59.2434 55.4027 59.9427 54.854 59.9427 54.1772C59.9427 53.599 59.4324 53.1144 58.7461 52.9854Z"
      fill="#78A0B8"
    />
    <defs>
      <linearGradient
        id="paint0_linear_19_13692"
        x1="45.7504"
        y1="47.4629"
        x2="45.7504"
        y2="70.7147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1D5BAE" />
        <stop offset="1" stop-color="#2974D9" />
      </linearGradient>
    </defs>
  </svg>
)
export default Svg
