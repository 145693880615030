import Icon from '@/assets/icons'
import styles from './CopyText.module.scss'
import { useState } from 'react'

enum CopyState {
  Default = 'DEFAULT',
  Copied = 'COPIED',
  Failed = 'FAILED',
}

export default function CopyText({ value }: { value: string }) {
  const [copyState, setCopyState] = useState<CopyState>(CopyState.Default)

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value)
      setCopyState(CopyState.Copied)
      setTimeout(() => setCopyState(CopyState.Default), 1000)
    } catch (err) {
      setCopyState(CopyState.Failed)
      setTimeout(() => setCopyState(CopyState.Default), 1000)
    }
  }

  if (copyState === CopyState.Copied) {
    return (
      <div className={styles.copiedIcon}>
        <Icon name="check-icon" />
      </div>
    )
  }

  if (copyState === CopyState.Failed) {
    return (
      <div className={styles.failedIcon}>
        <Icon name="failed-icon" />
      </div>
    )
  }

  return (
    <div onClick={copyTextToClipboard} className={styles.defaultIcon} title="Скопировать">
      <Icon name="copy-icon" />
    </div>
  )
}
