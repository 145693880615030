import { Container, Card, Button } from '@/components'
import { getAreaInfoById } from '@/store/slices/stagesSlice/stagesSlice'
// import AppLinks from '../_common/AppLinks'
import styles from './NotFoundPage.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useRouter } from 'next/router'
import { getNumberLink } from '@/helpers/getNumberLink'

export default function NotFoundPage({ areaId }: { areaId: string }) {
  const router = useRouter()
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId)
  const phoneLink = getNumberLink(areaInfo?.phone)

  const goBack = () => {
    router.back()
  }

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        {/* <Title>Активная парковка</Title> */}

        <div className={styles.content}>
          <Card className={styles.card} color="decor">
            <div className={styles.card_Image}>
              <img src="/images/car-not-found.svg" alt="" />
            </div>
            <div className={styles.card_Text}>У вас нет активной сессии</div>
          </Card>

          {/* <AppLinks /> */}
        </div>

        <footer className={styles.section__footer}>
          <Button
            // disabled={value.length <= 2}
            type="submit"
            withLoader
            // loading={loading}
            // onClick={_onButtonClick}
            className={styles.button}
            onClick={goBack}
            background={areaInfo?.customising.button_back_color}
          >
            Проверить гос. номер
          </Button>
          {phoneLink && (
            <Button component="a" href={phoneLink} className={styles.button} isTransparent>
              Обратиться в поддержку
            </Button>
          )}
        </footer>
      </Container>
    </section>
  )
}
