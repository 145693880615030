import { createPortal } from 'react-dom'
import { PureComponent } from 'react'

export default class Portal extends PureComponent {
  el: HTMLDivElement

  constructor(props: {} | Readonly<{}>) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    document.body.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    return createPortal(this.props.children, this.el)
  }
}
