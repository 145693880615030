import { useContext, createContext } from 'react'

const HostContext = createContext('')

type HostProviderProps = {
  host: string
  children: React.ReactNode
}

export const HostProvider = ({ host, children }: HostProviderProps) => {
  return <HostContext.Provider value={host}>{children}</HostContext.Provider>
}

export function useHost() {
  return useContext(HostContext)
}
