import * as React from 'react'
const Svg = () => (
  <svg
    width="198"
    height="198"
    viewBox="0 0 198 198"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_36_180"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="198"
      height="198"
    >
      <g clip-path="url(#clip0_36_180)">
        <mask
          id="mask1_36_180"
          maskUnits="userSpaceOnUse"
          x="18"
          y="27"
          width="162"
          height="145"
        >
          <path
            d="M141.558 31.6944L26.9608 64.9482C23.4446 65.9685 21.4214 69.646 22.4417 73.1622L48.3058 162.293C49.3261 165.81 53.0036 167.833 56.5197 166.813L171.117 133.559C174.633 132.538 176.657 128.861 175.636 125.345L149.772 36.2135C148.752 32.6973 145.074 30.6741 141.558 31.6944Z"
            stroke="white"
            stroke-width="8"
            stroke-linejoin="round"
          />
          <path
            d="M77.6799 108.903C81.0569 107.923 83.9064 105.642 85.6013 102.561C87.2963 99.4799 87.698 95.8519 86.7181 92.4749C85.7382 89.0979 83.4568 86.2485 80.376 84.5535C77.2952 82.8585 73.6672 82.4568 70.2902 83.4367C66.9132 84.4167 64.0637 86.698 62.3688 89.7788C60.6738 92.8596 60.2721 96.4876 61.252 99.8646C62.2319 103.242 64.5133 106.091 67.5941 107.786C70.6749 109.481 74.3029 109.883 77.6799 108.903Z"
            stroke="white"
            stroke-width="8"
            stroke-linejoin="round"
          />
          <path
            d="M102.322 122.46C100.852 117.395 97.4301 113.12 92.8088 110.578C88.1876 108.035 82.7456 107.433 77.6801 108.903C72.6146 110.373 68.3404 113.795 65.7979 118.416C63.2555 123.037 62.6529 128.479 64.1228 133.545M108.077 82.8256L133.543 75.4359M121.834 106.444L140.933 100.902"
            stroke="white"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </mask>
        <g mask="url(#mask1_36_180)">
          <path
            d="M4.18888 47.0695L151.223 4.40314L193.89 151.437L46.8553 194.104L4.18888 47.0695Z"
            fill="white"
            stroke="white"
            stroke-width="6"
          />
        </g>
      </g>
    </mask>
    <g mask="url(#mask0_36_180)">
      <rect y="1" width="198" height="197" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_36_180">
        <rect
          width="159.1"
          height="159.1"
          fill="white"
          transform="translate(0.47168 45.0244) rotate(-16.1817)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default Svg
