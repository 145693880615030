import { MetaData } from '@/components'
import Error from '@/components/Error'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { NextPage } from 'next'

interface ErrorProps {
  statusCode?: number
}

const NextError: NextPage<ErrorProps> = ({ statusCode = 500 }) => {
  useEffect(() => {
    NProgress.done(true)
  }, [])

  const is404page = statusCode === 404

  const title404 = 'Ошибка 404. Страница не найдена'
  const title500 = 'Ошибка 500. Внутренняя ошибка сервера'

  return (
    <>
      <MetaData title={is404page ? title404 : title500} />
      <Error statusCode={statusCode} />
    </>
  )
}

NextError.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  if (res && statusCode) {
    res.statusCode = statusCode
  }
  return { statusCode }
}

export default NextError
