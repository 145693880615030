import { AreaSubscriptionTypeWork } from '@/store/slices/stagesSlice/interfaces'
import wordEnd from '@/helpers/wordEnd'

interface GetSubscriptionIntervalArgs {
  type_work: AreaSubscriptionTypeWork
  interval_type: string | null
  interval: number | null
  fixed_period_from: string | null
  fixed_period_to: string | null
}

const getSubscriptionInterval = ({
  type_work,
  interval_type,
  interval,
  fixed_period_from,
  fixed_period_to,
}: GetSubscriptionIntervalArgs) => {
  if (type_work === AreaSubscriptionTypeWork.PaidDatePlusInterval && interval) {
    return `${interval} ${
      interval_type == 'day'
        ? wordEnd(interval, ['день', 'дня', 'дней'])
        : wordEnd(interval, ['месяц', 'месяца', 'месяцев'])
    }`
  }

  if (type_work === AreaSubscriptionTypeWork.FixedPeriod && fixed_period_from && fixed_period_to) {
    const dateFrom = new Date(fixed_period_from).toLocaleDateString()
    const dateTo = new Date(fixed_period_to).toLocaleDateString()
    return `C ${dateFrom} по ${dateTo}`
  }
}

export default getSubscriptionInterval
