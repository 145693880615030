import { forwardRef, useRef } from 'react'
import { ButtonProps } from './interfaces'
import styles from './Button.module.scss'
import mergeRefs from 'react-merge-refs'
import { Icon } from '@/components'
import cn from 'classnames'

const Button = forwardRef((props: ButtonProps, buttonRef) => {
  const {
    component: Component = 'button',
    loading = false,
    withLoader = false,
    disabled = false,
    color = 'primary',
    className,
    children,
    isTransparent,
    background,
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)

  return (
    <Component
      ref={mergeRefs([ref, buttonRef])}
      className={cn(
        styles.button,
        {
          [styles.disabled]: loading ? false : disabled,
          [styles.loading]: withLoader ? loading : false,
          [styles.secondary]: color === 'secondary',
          [styles.accent]: color === 'accent',
          [styles.transparent]: isTransparent,
        },
        className,
      )}
      disabled={disabled}
      style={{ background: !disabled ? background : '' }}
      {...rest}
    >
      <span className={styles.content}>{children}</span>

      {withLoader && (
        <span className={styles.spinner}>
          <Icon name="spinner" />
        </span>
      )}
    </Component>
  )
})

export default Button
