import { useHost } from '@/contexts/HostContext'
import { useRouter } from 'next/router'
import Head from 'next/head'

type MetaDataProps = {
  title?: string
  description?: string
  image?: string
  imageSmall?: string
}

export default function MetaData({
  title: titleFromProps,
  description: descriptionFromProps,
  image,
  imageSmall,
}: MetaDataProps) {
  const host = useHost()
  const origin = `https://${host}`

  const router = useRouter()
  const path = router.asPath.split('?')[0]
  const url = `${origin}${path}`

  // TODO: add og title/description, images

  // const defaultImages = {
  //   rect: `${origin}/images/og_image_rect.jpg`,
  //   square: `${origin}/images/og_image_sq.jpg`,
  // }

  // const { rect: imageRect, square: imageSquare } = defaultImages

  let title = titleFromProps || 'HippoParking'
  let description = descriptionFromProps || 'HippoParking'

  const imageRect = image
  const imageSquare = imageSmall

  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="crm.hippoparking.ru" />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content="ru_RU" />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;700;800&display=swap"
        rel="stylesheet"
      ></link>

      {imageRect && (
        <>
          <meta property="og:image" content={imageRect} />
          <meta property="og:image:secure_url" content={imageRect} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/jpeg" />
        </>
      )}

      {imageSquare && (
        <>
          <meta property="og:image" content={imageSquare} />
          <meta property="og:image:secure_url" content={imageSquare} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary" />
          <meta property="twitter:image" content={imageSquare} />
        </>
      )}
    </Head>
  )
}
