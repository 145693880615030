import { Button, CheckBox, Container, Icon, Paragraph, Select, Title } from '@/components'
import styles from './UserPage.module.scss'
import { useCallback, useEffect, useState } from 'react'
import { RootState } from '@/store'
import {
  getAreaInfoById,
  getSubscriptionInfoById,
  setUserInfo,
} from '@/store/slices/stagesSlice/stagesSlice'
import { useDispatch, useSelector } from 'react-redux'
import getLinkHref from '@/helpers/getLinkHref'
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles'
import { useRouter } from 'next/router'
import { getCookie, setCookie } from 'cookies-next'
import { getPlaceholder } from './utils'
import { CustomInput, CustomMaskInput, PassportBlock, RadioList } from './components'
import {
  LINK_PARKING_RULES,
  LINK_PERSONAL_DATA,
  USER_DATA,
  genderData,
  hasYoungChildrenData,
  orderArray,
  passportFields,
  sourceOptions,
} from './config'

const UserPage = ({ number, subscriptionId, stageId }: { number: string; subscriptionId: string, stageId: string | null }) => {
  const router = useRouter()
  useAreaStyles(router.query.areaId as string)
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data } = getSubscriptionInfoById(reduxState, subscriptionId)
  const { data: areaInfo } = getAreaInfoById(reduxState, router.query.areaId as string)
  const cookieUserData = getCookie(USER_DATA) as string
  const [additionalFields, setAdditionalFields] = useState(data?.subscription.additional_fields)
  const [values, setValues] = useState(
    additionalFields?.reduce(
      (acc: any, n) => (
        n.name === 'gender'
          ? (acc[n.name] = 'male')
          : n.name === 'has_young_children'
          ? (acc[n.name] = 'yes')
          : (acc[n.name] = null),
        acc
      ),
      {},
    ),
  )
  const [errors, setErrors] = useState(
    additionalFields?.reduce((acc: any, n) => ((acc[n.name] = null), acc), {}),
  )
  const [dataProcessing, setDataProcessing] = useState<boolean>(
    data?.area.link_processing_personal_data ? !!getCookie(LINK_PERSONAL_DATA) : true,
  )
  const [rulesUsingParking, setRulesUsingParking] = useState<boolean>(
    data?.area.link_parking_rules ? !!getCookie(LINK_PARKING_RULES) : true,
  )

  const sortAdditionalFields = () => {
    const arrayForSort = additionalFields ? [...additionalFields] : []
    arrayForSort?.sort(function (a, b) {
      let aValueIndex = orderArray.indexOf(a.name)
      let bValueIndex = orderArray.indexOf(b.name)
      if (aValueIndex === -1) {
        aValueIndex = orderArray.length
      }
      if (bValueIndex === -1) {
        bValueIndex = orderArray.length
      }
      return aValueIndex - bValueIndex
    })
    setAdditionalFields(arrayForSort)
  }

  useEffect(() => {
    sortAdditionalFields()
    cookieUserData && setValues({ ...values, ...JSON.parse(cookieUserData) })
  }, [])

  useEffect(() => {
    if (rulesUsingParking && dataProcessing) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }

    const errorData = additionalFields?.map(item =>
      item.is_required && (values[item.name] === null || values[item.name] === '') ? true : false,
    )

    if (
      !!errors.phone ||
      !!errors.birthday ||
      values.phone === '+7 (___) ___-__-__' ||
      values.birthday === '__/__/____' ||
      values.passport_date === '__/__/____' ||
      errorData?.includes(true)
    ) {
      setIsDisabled(true)
    }
  }, [values, rulesUsingParking, dataProcessing, errors])

  const validateValues = () => {
    const errorData = additionalFields?.map(item => {
      if (item.is_required && (values[item.name] === null || values[item.name] === '')) {
        setErrors((prev: any) => {
          return {
            ...prev,
            [item.name]: `Поле ${getPlaceholder(item.name)} обязательно для заполнения`,
          }
        })
        return true
      } else {
        return false
      }
    })
    return errorData?.includes(true)
  }

  const _onSubmit = () => {
    const existedErrors = validateValues()
    if (existedErrors || !!errors.phone || !!errors.birthday) {
    } else {
      setCookie(USER_DATA, JSON.stringify(values))
      dispatch(
        setUserInfo({ ...values, permissions: { data_processing: true, parking_rules: true } }),
      )
      const areaId: string = router.query.areaId as string
      const paymentHref = getLinkHref({
        type: 'subscription-payment',
        data: { areaId, subscriptionId, number, stageId: stageId || null },
      })
      router.push(paymentHref)
    }
  }

  const onChange = useCallback(
    (field: string) => (e: any) => {
      setErrors((prev: any) => {
        return { ...prev, [field]: null }
      })
      setValues((prev: any) => {
        return prev ? { ...prev, [field]: e.target.value } : { [field]: e.target.value }
      })
    },
    [],
  )

  const onChangeGender = useCallback((gender: 'male' | 'female') => {
    setValues((prev: any) => {
      return { ...prev, gender }
    })
  }, [])

  const onChangeChildren = useCallback((has_young_children: 'yes' | 'no') => {
    setValues((prev: any) => {
      return { ...prev, has_young_children }
    })
  }, [])

  const onChangeSource = useCallback((source: string) => {
    setValues((prev: any) => {
      return { ...prev, source }
    })
  }, [])

  const onChangeStateLinks = (type: 'personalData' | 'parkingRules') => () => {
    type === 'personalData' ? setDataProcessing(prev => !prev) : setRulesUsingParking(prev => !prev)
    setCookie(type === 'personalData' ? LINK_PERSONAL_DATA : LINK_PARKING_RULES, true)
  }

  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <Container>
          <header className={styles.header}>
            <Title className={styles.price}>Расскажите о себе</Title>
            <Paragraph>
              Это необходимо для того, чтоб мы могли предоставлять вам скидки и специальные
              предложения
            </Paragraph>
          </header>
        </Container>

        <Container className={styles.container}>
          <div onSubmit={_onSubmit} className={styles.form}>
            <div className={styles.form__content}>
              {additionalFields?.map(field => {
                switch (field.name) {
                  case 'birthday':
                    return (
                      <CustomMaskInput
                        key={field.name}
                        setErrors={setErrors}
                        fieldName={field.name}
                        isRequired={field.is_required}
                        mask={'99/99/9999'}
                        placeholder={'Дата рождения'}
                        error={errors[field.name]}
                        value={values[field.name]}
                        onChange={onChange(field.name)}
                        type={'date'}
                      />
                    )
                  case 'phone':
                    return (
                      <CustomMaskInput
                        key={field.name}
                        setErrors={setErrors}
                        fieldName={field.name}
                        isRequired={field.is_required}
                        mask={'+7 (999) 999-99-99'}
                        placeholder={'+7 (999) 999-99-99'}
                        error={errors[field.name]}
                        value={values[field.name]}
                        onChange={onChange(field.name)}
                        icon={<Icon name="flag" />}
                      />
                    )
                  case 'gender':
                    return (
                      <RadioList
                        key={field.name}
                        data={genderData}
                        title={'Ваш пол'}
                        onChange={onChangeGender}
                        currentValue={values.gender}
                      />
                    )
                  case 'has_young_children':
                    return (
                      <RadioList
                        key={field.name}
                        data={hasYoungChildrenData}
                        title={'Есть ли дети до 18 лет?'}
                        onChange={onChangeChildren}
                        currentValue={values.has_young_children}
                      />
                    )
                  case 'source':
                    return (
                      <div className={styles.source}>
                        <Select
                          key={field.name}
                          options={sourceOptions}
                          value={values[field.name]}
                          onSelectChange={onChangeSource}
                          placeholder="Откуда узнали о нас"
                          isRequired={field.is_required}
                        />
                      </div>
                    )
                  case 'passport_number':
                    return (
                      <PassportBlock
                        key={field.name}
                        onChange={onChange}
                        isRequired={field.is_required}
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    )
                  default:
                    return (
                      !passportFields.includes(field.name) && (
                        <CustomInput
                          key={field.name}
                          fieldName={field.name}
                          onChange={onChange(field.name)}
                          isRequired={field.is_required}
                          value={values[field.name]}
                          error={errors[field.name]}
                          danger={!!values[field.name]?.value}
                        />
                      )
                    )
                }
              })}

              {data?.area.link_processing_personal_data && (
                <CheckBox
                  name={'dataProcessing'}
                  checked={dataProcessing}
                  title={`<span>Я согласен <a href=${data?.area.link_processing_personal_data} target='_blank'>на обработку Персональных данных</a></span>`}
                  onChange={onChangeStateLinks('personalData')}
                />
              )}

              {data?.area.link_parking_rules && (
                <CheckBox
                  name={'rulesUsingParking'}
                  checked={rulesUsingParking}
                  title={`<span>Я согласен с <a href=${data?.area.link_parking_rules} target='_blank'>Правилами пользования парковкой</a></span>`}
                  onChange={onChangeStateLinks('parkingRules')}
                />
              )}
            </div>

            <Button
              disabled={isDisabled}
              onClick={_onSubmit}
              className={styles.button}
              background={areaInfo?.customising.button_back_color}
            >
              Продолжить
            </Button>
          </div>
        </Container>
      </div>
    </section>
  )
}

export default UserPage
