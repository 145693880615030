import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '36',
  height = '26',
  viewBox = '0 0 36 26',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.833 19.608 32.983.456l2.948 2.946L13.833 25.5.575 12.242l2.946-2.946 10.312 10.312Z" />
  </svg>
)

export default SVG
