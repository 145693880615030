import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ReceiptFields,
  ReceiptGeneration,
  ReceiptIsReady,
  PaymentReceiptHeader,
  PaymentReceiptError,
  PaymentReceiptLoading,
  PaymentConfirmation,
  PaymentSteps,
} from './components'
import { getPaymentReceiptByOrderNumber } from '@/store/slices/stagesSlice/stagesSlice'
import { RootState } from '@/store'
import { PaymentStatus, PaymentStatusComponent } from './types'
import { useFetchPaymentReceipt, usePaymentStatus, useTimer } from './hooks'
import styles from './PaymentReceiptPage.module.scss'

const MemoizedPaymentReceiptHeader = memo(PaymentReceiptHeader)
const MemoizedPaymentSteps = memo(PaymentSteps)

export default function PaymentReceiptPage({ orderNumber }: { orderNumber: string }) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    PaymentStatus.PaymentConfirmation,
  )
  const { data: paymentReceiptInfo, error, loading } = getPaymentReceiptByOrderNumber(
    reduxState,
    orderNumber,
  )
  const { receipt_link, ofd_url, fields } = paymentReceiptInfo ?? {}

  const { timer, displayTimer } = useTimer()
  usePaymentStatus({ setPaymentStatus, error, receipt_link, ofd_url, fields })
  useFetchPaymentReceipt({ orderNumber, timer, ofd_url, receipt_link })

  if (loading) {
    return <PaymentReceiptLoading />
  }

  if (error && error.statusCode !== 404) {
    return <PaymentReceiptError />
  }

  const paymentStatusComponent: PaymentStatusComponent = {
    [PaymentStatus.PaymentConfirmation]: <PaymentConfirmation timer={displayTimer} />,
    [PaymentStatus.ReceiptGeneration]: <ReceiptGeneration timer={displayTimer} />,
    [PaymentStatus.ReceiptIsReady]: receipt_link ? (
      <ReceiptIsReady receiptLink={receipt_link} />
    ) : null,
    [PaymentStatus.ReceiptFields]:
      ofd_url && fields ? <ReceiptFields ofdUrl={ofd_url} receiptFields={fields} /> : null,
  }

  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <MemoizedPaymentReceiptHeader paymentStatus={paymentStatus} />
        <main className={styles.main}>
          <section className={styles.main__content}>
            <MemoizedPaymentSteps paymentStatus={paymentStatus} />
            {paymentStatusComponent[paymentStatus]}
          </section>
        </main>
      </div>
    </div>
  )
}
