import styles from './Paragraph.module.scss'
import cn from 'classnames'

type ParagraphProps = {
  medium?: boolean
  align?: 'left' | 'center'
  children: React.ReactNode
  bold?: boolean
  className?: string
  level?: number
}

export default function Paragraph({
  medium = false,
  align = 'left',
  children,
  bold = false,
  className = '',
  level = 1,
}: ParagraphProps) {
  return (
    <p
      className={cn(
        styles.paragraph,
        styles[`paragraph--level-${level}`],
        {
          [styles.medium]: medium,
          [styles.center]: align === 'center',
          [styles.bold]: !!bold,
        },
        className,
      )}
    >
      {children}
    </p>
  )
}
