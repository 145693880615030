import styles from './Text.module.scss'
import cn from 'classnames'

type TextProps = {
  size?: 18
  level?: number
  className?: string
  isBold?: boolean
  children: React.ReactNode
}

export default function Text({ size = 18, level = 1, className, isBold, children }: TextProps) {
  return (
    <p
      className={cn(
        styles.text,
        styles[`size-${size}`],
        styles[`level-${level}`],
        { [styles[`bold`]]: isBold },
        className || '',
      )}
    >
      {children}
    </p>
  )
}
