import getDate from '@/helpers/getDate'

type DateType = {
  'дн.'?: number
  'ч.'?: number
  'мин.'?: number
}

export default function Time({ timeInSeconds }: { timeInSeconds: number }) {
  return <span>{getTimeString(timeInSeconds)}</span>
}

function getTimeString(timeInSeconds: number) {
  const { days, hours, minutes } = getDate(timeInSeconds)

  const obj: DateType = {}
  let date = ''

  obj['дн.'] = days
  obj['ч.'] = hours
  obj['мин.'] = minutes

  for (const [key, value] of Object.entries(obj)) {

    if (key === 'дн.' && value == 0) {
      continue
    }

    date += ` ${value} ${key}`
  }

  return obj ? date : 0
}
