import { getCookie } from 'cookies-next'
import { checkisCoupon } from './checkIsCoupon'
import { NUMBER_CAR } from '@/constants'

export const getFormattedCarNumber = () => {
  let carNumber = String(getCookie(NUMBER_CAR) || '')
  const isCoupon = checkisCoupon(carNumber)
  return isCoupon
    ? (carNumber = carNumber.slice(0, 3) + ' ' + carNumber.slice(3, 6) + ' ' + carNumber.slice(6))
    : carNumber
}
