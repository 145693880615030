import Link from 'next/link'
import { Icon, Title, Button } from '@/components'
import cn from 'classnames'
import styles from './SubscriptionFieldsPage.module.scss'

export default function SubscriptionNotFoundPage({ areaHref }: { areaHref: string }) {
  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <header className={styles.headerEmpty}>
          <div className={styles.header__top}>
            <Link href={areaHref}>
              <div className={styles.header__logo}>
                <img
                  className={styles.logoImage}
                  src="/images/logo.png"
                  alt="Логотип HippoParking"
                />
                <Title tag="span" className={cn(styles.logoTitle, styles.blueLogoTitle)}>
                  HippoParking
                </Title>
              </div>
            </Link>
            <a href={'tel:88002003320'} className={styles.header__phone}>
              <Icon name="fill-phone-icon" />
            </a>
          </div>
        </header>
        <main className={styles.mainErrorPage}>
          <img src="/images/404.png" alt="Ошибка 404" className={styles.errorImage} />
          <p className={styles.errorMessage}>
            Кажется, что-то пошло не так! Страница, которую вы запрашиваете, не существует.
            Возможно, она устарела, была удалена или был введен неверный адрес.
          </p>
          <Link href={areaHref}>
            <Button className={styles.onMainButton}>Перейти на главную</Button>
          </Link>
        </main>
      </div>
    </div>
  )
}
