import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef } from 'react'
import cn from 'classnames'

import FormError from '@/components/FormError/FormError'
import Input from '../Input/Input'
import { InputProps } from '../Input/interfaces'
import DropContainer from '@/components/DropContainer'
import EmailDomainList from './EmailDomainList'
import styles from './EmailInput.module.scss'

type EmailInputProps = Pick<InputProps, 'onKeyPress' | 'value' | 'name'> & {
  error?: string
  className?: string
  onChange: (value: string) => void
  classNameInput?: string
}

export const emailDomainsList = [
  '@mail.ru',
  '@gmail.com',
  '@yandex.ru',
  '@bk.ru',
  '@inbox.ru',
  '@list.ru',
  '@icloud.com',
  '@rambler.ru',
  '@ya.ru',
  '@me.com',
  '@yahoo.com',
  '@hotmail.com',
  '@outlook.com',
  '@vk.com',
]

const EmailInput = ({
  onChange,
  onKeyPress,
  value,
  error,
  name = 'text',
  className = '',
  classNameInput = '',
}: EmailInputProps) => {
  const fieldRef = useRef(null)
  const isDropListVisible = useMemo(() => value.includes('@'), [value])

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isDropListVisible && event.key === '@') {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    onKeyPress?.(event)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  useEffect(() => {
    if (error) {
      const field = (fieldRef.current as unknown) as HTMLInputElement
      field.focus()
    }
  }, [error])

  return (
    <div>
      <label className={cn(styles.input, className)}>
        <FormError error={error} className={styles.input__error}>
          <Input
            name={name}
            value={value}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            ref={fieldRef}
            // inputMode="email"
            placeholder="email@example.com"
            danger={!!error}
            className={classNameInput}
          />
        </FormError>
      </label>

      <DropContainer isVisible={isDropListVisible}>
        <EmailDomainList list={emailDomainsList} value={value} onChange={onChange} />
      </DropContainer>
    </div>
  )
}

export default EmailInput
