import { IconProps } from "./interfaces"

const SVG = ({fill = '#3879F0'}: IconProps) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 8.25L11 13.75L16.5 8.25"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVG
