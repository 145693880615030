export enum PaymentStatus {
  PaymentConfirmation = 'PAYMENT_CONFIRMATION',
  ReceiptGeneration = 'RECEIPT_GENERATION',
  ReceiptIsReady = 'RECEIPT_IS_READY',
  ReceiptFields = 'RECEIPT_FIELDS',
}

export type PaymentStatusComponent = {
  [key in PaymentStatus]: JSX.Element | null
}
