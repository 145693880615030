const SVG = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="block"
    width="16"
    height="16"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M856 376H648V168c0-8.8-7.2-16-16-16H168c-8.8 0-16 7.2-16 16v464c0 8.8 7.2 16 16 16h208v208c0 8.8 7.2 16 16 16h464c8.8 0 16-7.2 16-16V392c0-8.8-7.2-16-16-16zm-480 16v188H220V220h360v156H392c-8.8 0-16 7.2-16 16zm204 52v136H444V444h136zm224 360H444V648h188c8.8 0 16-7.2 16-16V444h156v360z"></path>
  </svg>
)

export default SVG
