import '@/scripts/wdyr'
import '@/assets/styles/vars/css-variables.scss'
import '@/assets/styles/vars/css-slug-variables.scss'
import '@/assets/styles/fonts.scss'
import '@/assets/styles/global.scss'

import type { AppProps, AppContext, AppInitialProps } from 'next/app'
import { Head, NProgress, Layout } from '@/components'
import { HostProvider } from '@/contexts/HostContext'
import { getHost } from '@/helpers/getHost'
import { Provider } from 'react-redux'
import { useStore } from '@/store'
import Error from './_error'
import App from 'next/app'

interface MyAppProps extends AppProps {
  host: string
  hasError: boolean
  errorStatus: number
}

export default function MyApp({ Component, pageProps, host, hasError, errorStatus }: MyAppProps) {
  const { initialReduxState, headerLayout, ...otherPageProps } = pageProps || {}
  const store = useStore(initialReduxState)

  return hasError ? (
    <Provider store={store}>
      <Head />
      <Layout>
        <NProgress />
        <Error {...otherPageProps} statusCode={errorStatus} />
      </Layout>
    </Provider>
  ) : (
    <Provider store={store}>
      <Head />
      <HostProvider host={host}>
        <NProgress />
        <Layout headerLayout={headerLayout}>
          <Component {...otherPageProps} />
        </Layout>
      </HostProvider>
    </Provider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { Component, ctx } = appContext
  let errorStatus = ctx.err ? ctx.err.statusCode : ctx.res ? ctx.res.statusCode : null
  let hasError = false
  let appProps: AppInitialProps = { pageProps: {} }

  try {
    appProps = await App.getInitialProps(appContext)
  } catch (error: any) {
    errorStatus = +(error?.statusCode ?? 500)
    hasError = true
    if (ctx.res && errorStatus) ctx.res.statusCode = errorStatus
  }

  const host = getHost(appContext.ctx.req)

  return { ...appProps, host, hasError: Component === Error || hasError, errorStatus }
}
