import {
  forwardRef,
  useRef,
  memo,
  useCallback,
  ChangeEventHandler,
  useState,
  useEffect,
} from 'react'
import { InputProps } from './interfaces'
import styles from './Input.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'
import InputMask from 'react-input-mask'

const Input = memo(
  forwardRef((props: InputProps, inputRef) => {
    const {
      id,
      value,
      name,
      onChange,
      onPaste,
      onBlur,
      onFocus,
      placeholder = '',
      danger = false,
      disabled = false,
      type = 'text',
      inputMode = 'text',
      onKeyPress,
      mask: initMask,
      icon,
      isDiscountPage = false,
      className,
    } = props
    const ref = useRef<HTMLInputElement>(null)
    const [mask, setMask] = useState(initMask)

    useEffect(() => {
      if (type === 'date') {
        setMask([
          /^([0-3])/,
          value?.[0] === '3' ? /([0-1])/ : /([0-9])/,
          '/',
          /([0-1])/,
          value?.[3] === '1' ? /([0-2])/ : value?.[0] === '3' ? /(1|[3-9])/ : /([1-9])/,
          '/',
          /([1-2])/,
          value?.[6] === '2' ? /(0)/ : /(9)/,
          /([0-9])/,
          /([0-9])/,
        ])
      }
    }, [initMask, value])

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        if (onChange) onChange(e)
      },
      [onChange],
    )

    if (mask) {
      return (
        <div className={styles.input__wraper}>
          {icon && <div className={styles.input__icon}>{icon}</div>}
          <InputMask
            mask={mask}
            value={value}
            onChange={_onChange}
            className={cn(styles.input, className, {
              [styles.danger]: danger,
              [styles.input_withIcon]: icon,
              [styles.input_disabled]: disabled,
            })}
            placeholder={placeholder}
            maskChar="_"
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
          />
        </div>
      )
    }

    return (
      <input
        id={id}
        ref={mergeRefs([ref, inputRef])}
        value={value}
        onChange={_onChange}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        className={cn(styles.input, className, {
          [styles.danger]: danger,
          [styles.input_isDiscountPage]: isDiscountPage,
          [styles.input_disabled]: disabled,
        })}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        inputMode={inputMode}
        name={name}
      />
    )
  }),
)

export default Input
