import { memo, forwardRef, useRef, useCallback, ChangeEventHandler } from 'react'
import { CheckBoxProps } from './interfaces'
import styles from './Choice.module.scss'
import mergeRefs from 'react-merge-refs'

const CheckBox = memo(
  forwardRef((props: CheckBoxProps, inputRef) => {
    const { checked, onChange, name, disabled = false, title, ...rest } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        onChange(e.target.checked)
      },
      [onChange],
    )

    return (
      <label className={styles.checkBoxInput__wrapper} {...rest}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={_onChange}
          className={styles.checkBoxInput}
          ref={mergeRefs([ref, inputRef])}
        />
        <span className={styles.checkbox}></span>
        <div className={styles.checkBoxInput__title} dangerouslySetInnerHTML={{ __html: title }} />
      </label>
    )
  }),
)

export default CheckBox
