import { Rate } from './interfaces'
import styles from './PaymentPage.module.scss'
import unicode from '@/helpers/unicodeChars'
import getDate from '@/helpers/getDate'
import formatNumberToString from '@/helpers/formatNumberToString'
import Title from '@/components/Typography/Title/Title'
import Text from '@/components/Typography/Text/Text'

export default function Rates({ rates }: { rates: Rate[] }) {
  return (
    <div className={styles.rates}>
      <Title level={4} className={styles.rates_Title}>
        К оплате
      </Title>

      {rates.map((rate, idx) => {
        const { from, roundingTo, pricePerHour, amount } = rate

        const fromTime = getDate(from)
        const fromTimeString = getTimeString(fromTime.days, fromTime.hours, fromTime.minutes)

        const toTime = getDate(roundingTo)
        const toTimeString = getTimeString(toTime.days, toTime.hours, toTime.minutes)

        return (
          <div key={idx} className={styles.rate}>
            <Text level={2}>
              {rate.title != null ? rate.title : 'От ' + fromTimeString + ' до ' + toTimeString}
            </Text>
            <Text level={2}>
              {rate.title_price != null
                ? rate.title_price
                : pricePerHour.toString() + unicode.no_break_space + unicode.ruble + '/ч'}
            </Text>
            <Text level={2}>
              {formatNumberToString(+amount)}
              {unicode.no_break_space}
              {unicode.ruble}
            </Text>
          </div>
        )
      })}

      {/*<div className={styles.rates_Footer}>*/}
      {/*  <Text level={2} isBold>*/}
      {/*    Итого:*/}
      {/*  </Text>*/}
      {/*  <Text level={2} isBold>*/}
      {/*    {formatNumberToString(+total)}*/}
      {/*    {unicode.no_break_space}*/}
      {/*    {unicode.ruble}*/}
      {/*  </Text>*/}
      {/*</div>*/}
    </div>
  )
}

function getTimeString(days: number, hours: number, minutes: number) {
  const arr = []

  if (days > 0) arr.push(`${days} д`)
  if (hours > 0) {
    arr.push(`${hours} ч`)
  }
  if (minutes > 0) {
    arr.push(`${minutes} м`)
  }

  return arr.length > 0 ? arr.join(' ') : 0
}
