export type LinkProps = LinkType | ButtonType

type LinkType = {
  className?: string
  href: string
  children: React.ReactNode
}

type ButtonType = {
  className?: string
  button?: boolean
  onClick: () => void
  children: React.ReactNode
}

export function isLinkType(props: LinkType | ButtonType): props is LinkType {
  return !Object.keys(props).includes('button')
}
