import styles from './Title.module.scss'
import cn from 'classnames'

type TitleProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  tag?: 'span'
  size?: 32 | 24
  align?: 'left' | 'center'
  children: React.ReactNode
  className?: string
}

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export default function Title({
  level = 1,
  tag,
  size = 32,
  align = 'left',
  children,
  className,
}: TitleProps) {
  const headingTag = `h${level}` as HeadingTag
  const Component = tag ? tag : level ? headingTag : 'h1'

  return (
    <Component
      className={cn(
        styles.title,
        styles[`size-${size}`],
        {
          [styles.center]: align === 'center',
          [styles[`level-${headingTag}`]]: level,
        },
        className,
      )}
    >
      {children}
    </Component>
  )
}
