import { Title, Container, Paragraph, Button, FixedBottom, HalvaBanner } from '@/components'
import styles from './PaymentResult.module.scss'
import getLinkHref from '@/helpers/getLinkHref'
import Link from 'next/link'
import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'

export default function PaymentEventSuccessPage({
  paidUntilEvent,
  area,
}: {
  paidUntilEvent: any
  area: {
    id: string
    phone?: string
    customising?: { button_back_color: string | null }
  } | null
}) {
  const date = `${format(new Date(paidUntilEvent.start_at), 'dd MMMM', {
    locale: ru,
  })} ${format(new Date(paidUntilEvent.start_at), 'HH:mm', { locale: ru })}-${format(
    new Date(paidUntilEvent.end_at),
    'HH:mm',
    { locale: ru },
  )}`

  const areaHref = getLinkHref({ type: 'area', data: { id: area?.id } })

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <HalvaBanner />
        <div className={styles.successResultContent}>
          <img
            className={styles.icon}
            src="/images/payment-success.svg"
            alt="logo"
            width={87}
            height={87}
          />

          <Title align="center">Абонемент куплен!</Title>

          <div className={styles.subscriptionInfo}>
            {date && (
              <Paragraph align="center" className={styles.section__descr}>
                Действителен {date}.
              </Paragraph>
            )}
            <Paragraph align="center" className={styles.section__text}>
              Держите номер авто чистым для автоматического открытия шлагбаума.
            </Paragraph>
            <Paragraph align="center" className={styles.section__text}>
              Абонемент действует за 2 часа до начала и в течение 2 часов после завершения
              мероприятия
            </Paragraph>
          </div>
        </div>

        <FixedBottom isHide>
          <div className={styles.button}>
            <Link href={areaHref} passHref>
              <Button
                component="a"
                className={styles.button}
                background={area?.customising?.button_back_color}
              >
                На главную
              </Button>
            </Link>
          </div>
        </FixedBottom>
      </Container>
    </section>
  )
}
