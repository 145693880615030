const SVG = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7799_2779)">
      <rect width="18" height="4" fill="#F5F8FB" />
      <rect y="4" width="18" height="4" fill="#3256D3" />
      <rect y="8" width="18" height="4" fill="#EE4038" />
    </g>
    <defs>
      <clipPath id="clip0_7799_2779">
        <rect width="18" height="12" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SVG
