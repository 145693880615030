import { Icon, Title } from '@/components'
import cn from 'classnames'
import styles from '../PaymentReceiptPage.module.scss'

export default function PaymentReceiptErrorPage() {
  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <header className={styles.headerEmpty}>
          <div className={styles.header__top}>
            <div className={styles.header__logo}>
              <img className={styles.logoImage} src="/images/logo.png" alt="Логотип HippoParking" />
              <Title tag="span" className={cn(styles.logoTitle, styles.blueLogoTitle)}>
                HippoParking
              </Title>
            </div>
            <a href={'tel:88002003320'} className={styles.header__phone}>
              <Icon name="fill-phone-icon" />
            </a>
          </div>
        </header>
        <main className={styles.errorPage}>
          <img src="/images/error-page.png" alt="Ошибка" className={styles.errorImage} />
          <Title level={2} className={styles.errorTitle}>
            Ой, что-то пошло не так
          </Title>
          <p className={styles.errorMessage}>
            Возникла непредвиденная ошибка. Пожалуйста,{' '}
            <a href={'tel:88002003320'} className={styles.link}>
              обратитесь в поддержку
            </a>
          </p>
        </main>
      </div>
    </div>
  )
}
