import { Title, Button } from '@/components'
import styles from '../PaymentReceiptPage.module.scss'

export default function ReceiptIsReady({ receiptLink }: { receiptLink: string }) {
  return (
    <div>
      <Title level={2} className={styles.subtitle}>
        Ваш чек готов
      </Title>
      <p className={styles.description}>
        Переход на страницу чека осуществляется автоматически. Если переход не выполнился, то
        нажмите на кнопку.
      </p>

      <a href={receiptLink} target="_blank">
        <Button className={styles.button}>Посмотреть чек</Button>
      </a>
    </div>
  )
}
