import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getCookie, setCookie } from 'cookies-next'
import { Button, CheckBox, Icon, Title } from '@/components'
import { Area, IAdditionalField, AdditionalFieldNames } from '@/store/slices/stagesSlice/interfaces'
import {
  orderArray,
  passportFields,
  genderData,
  hasYoungChildrenData,
  sourceOptions,
  LINK_PERSONAL_DATA,
  LINK_PARKING_RULES,
} from './config'
import { getLabelText } from './utils'
import { CustomInput, CustomMaskInput, RadioList, CustomSelect, PassportBlock } from './components'
import styles from './SubscriptionFieldsPage.module.scss'
import { savePurchasedSubscriptionInfo } from '@/store/slices/stagesSlice/stagesSlice'
import { useDispatch } from 'react-redux'

export type FormErrors = {
  [key in AdditionalFieldNames]: string | null
}

export type FormValues = {
  [key in AdditionalFieldNames]: string
}

export default function SubscriptionPageForm({
  token,
  areaInfo,
  additionalFieldsData,
  savingSubscriptionInfo,
  setIsSuccessModalOpen,
  setIsErrorModalOpen,
}: {
  token: string
  areaInfo: Area
  additionalFieldsData: IAdditionalField[]
  savingSubscriptionInfo: boolean
  setIsSuccessModalOpen: Dispatch<SetStateAction<boolean>>
  setIsErrorModalOpen: Dispatch<SetStateAction<boolean>>
}) {
  const dispatch = useDispatch()
  const [additionalFields, setAdditionalFields] = useState<IAdditionalField[]>(additionalFieldsData)
  const isFormSavedBefore = !!additionalFields.find(item => {
    return item.name !== 'phone' && item.type === 'string' && item.value
  })
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(isFormSavedBefore)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false)

  const getFieldValue = (field: IAdditionalField): string => {
    if (field.name === 'has_young_children') {
      return field.value === null ? '' : field.value === '1' ? 'yes' : 'no'
    }
    return field.value ? field.value : ''
  }

  const [values, setValues] = useState<FormValues>(
    additionalFields?.reduce(
      (acc: FormValues, n: IAdditionalField) => ((acc[n.name] = getFieldValue(n)), acc),
      {} as FormValues,
    ),
  )

  const [errors, setErrors] = useState<FormErrors>(
    additionalFields?.reduce(
      (acc: FormErrors, n: IAdditionalField) => ((acc[n.name] = null), acc),
      {} as FormErrors,
    ),
  )

  const [dataProcessing, setDataProcessing] = useState<boolean>(
    areaInfo.link_processing_personal_data ? !!getCookie(LINK_PERSONAL_DATA) : true,
  )

  const [rulesUsingParking, setRulesUsingParking] = useState<boolean>(
    areaInfo.link_parking_rules ? !!getCookie(LINK_PARKING_RULES) : true,
  )

  const sortAdditionalFields = () => {
    const arrayForSort = additionalFields ? [...additionalFields] : []
    arrayForSort?.sort(function (a, b) {
      let aValueIndex = orderArray.indexOf(a.name)
      let bValueIndex = orderArray.indexOf(b.name)
      if (aValueIndex === -1) {
        aValueIndex = orderArray.length
      }
      if (bValueIndex === -1) {
        bValueIndex = orderArray.length
      }
      return aValueIndex - bValueIndex
    })
    setAdditionalFields(arrayForSort)
  }

  useEffect(() => {
    if (rulesUsingParking && dataProcessing) {
      setIsSaveButtonDisabled(false)
    } else {
      setIsSaveButtonDisabled(true)
    }

    const errorData = additionalFields?.map(item =>
      item.is_required && (values[item.name] === null || values[item.name] === '') ? true : false,
    )

    if (
      !!errors.phone ||
      !!errors.birthday ||
      values.phone === '+7 (___) ___-__-__' ||
      values.birthday === '__/__/____' ||
      values.passport_date === '__/__/____' ||
      errorData?.includes(true)
    ) {
      setIsSaveButtonDisabled(true)
    }
  }, [values, rulesUsingParking, dataProcessing, errors])

  const validateValues = () => {
    const errorData = additionalFields?.map(item => {
      if (item.is_required && (values[item.name] === null || values[item.name] === '')) {
        setErrors(prev => {
          return {
            ...prev,
            [item.name]: `Поле ${getLabelText(item.name)} обязательно для заполнения`,
          }
        })
        return true
      } else {
        return false
      }
    })
    return errorData?.includes(true)
  }

  const prepareFormValuesToSave = (values: FormValues) => {
    const { passport_number, passport_who_gave, passport_date } = values
    const data = []

    const getValue = (field: AdditionalFieldNames) => {
      if (field === 'birthday') {
        return values.birthday.replaceAll('/', '.')
      }
      if (field === 'has_young_children') {
        return values[field] === '' ? '' : values[field] === 'yes' ? true : false
      }
      return values[field]
    }

    let key: AdditionalFieldNames
    for (key in values) {
      if (!passportFields.includes(key)) {
        data.push({
          key: key,
          value: getValue(key),
        })
      }
    }

    if ('passport_number' in values) {
      const passport_data = {
        key: 'passport_data',
        value: [
          {
            key: 'passport_date',
            value: passport_date ? passport_date?.replaceAll('/', '.') : '',
          },
          {
            key: 'passport_number',
            value: passport_number || '',
          },
          {
            key: 'passport_who_gave',
            value: passport_who_gave || '',
          },
        ],
      }

      data.push(passport_data)
    }

    return data
  }

  const onFormSubmit = async () => {
    const existedErrors = validateValues()
    if (existedErrors || !!errors.phone || !!errors.birthday) {
    } else {
      await dispatch(
        savePurchasedSubscriptionInfo({
          token,
          additionalFields: prepareFormValuesToSave(values),
          onSuccess: () => {
            setIsSuccessModalOpen(true)
          },
          onError: () => {
            setIsErrorModalOpen(true)
          },
        }),
      )
    }
  }

  const onStartEditing = () => {
    if (!isFormDisabled) return
    setIsFormDisabled(false)
  }

  const onCancelEditing = () => {
    setValues(
      additionalFields?.reduce(
        (acc: FormValues, n: IAdditionalField) => ((acc[n.name] = getFieldValue(n)), acc),
        {} as FormValues,
      ),
    )
    setErrors(
      additionalFields?.reduce(
        (acc: FormErrors, n: IAdditionalField) => ((acc[n.name] = null), acc),
        {} as FormErrors,
      ),
    )
    setIsFormDisabled(true)
  }

  const onChange = (field: AdditionalFieldNames) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setErrors(prev => {
      return { ...prev, [field]: null }
    })

    setValues((prev => {
      return prev ? { ...prev, [field]: event.target.value } : { [field]: event.target.value }
    }) as SetStateAction<FormValues>)
  }

  const onChangeGender = (gender: 'male' | 'female') => {
    setValues(prev => {
      return { ...prev, gender }
    })
  }

  const onChangeChildren = (has_young_children: 'yes' | 'no') => {
    setValues(prev => {
      return { ...prev, has_young_children }
    })
  }

  const onChangeSource = (source: string) => {
    setValues(prev => {
      return { ...prev, source }
    })
  }

  const onChangeStateLinks = (type: 'personalData' | 'parkingRules') => () => {
    type === 'personalData' ? setDataProcessing(prev => !prev) : setRulesUsingParking(prev => !prev)
    setCookie(type === 'personalData' ? LINK_PERSONAL_DATA : LINK_PARKING_RULES, true)
  }

  useEffect(() => {
    sortAdditionalFields()
  }, [])

  const isShowStartEditingButton = isFormSavedBefore && isFormDisabled
  const isShowCheckboxes = !isFormDisabled
  const isShowonCancelEditingButton = isFormSavedBefore

  return (
    <section className={styles.section}>
      <div className={styles.titleWithButtonRow}>
        <Title level={2} className={styles.sectionTitle}>
          Расскажите о себе
        </Title>
        {isShowStartEditingButton && (
          <div>
            <button onClick={onStartEditing} className={styles.startEditingButton}>
              Редактировать <Icon name="edit-icon" />
            </button>
          </div>
        )}
      </div>
      <p className={styles.formDescription}>
        Это необходимо для того, чтобы мы могли предоставлять вам скидки и специальные предложения
      </p>

      <div className={styles.form} onSubmit={onFormSubmit}>
        {additionalFields.map(field => {
          switch (field.name) {
            case 'birthday':
              return (
                <CustomMaskInput
                  key={field.name}
                  setErrors={setErrors}
                  fieldName={field.name}
                  isRequired={field.is_required || false}
                  mask={'99/99/9999'}
                  error={errors[field.name]}
                  value={values[field.name]}
                  onChange={onChange(field.name)}
                  type={'date'}
                  danger={!!errors[field.name]}
                  isDisabled={isFormDisabled}
                />
              )
            case 'phone':
              return (
                <CustomMaskInput
                  key={field.name}
                  setErrors={setErrors}
                  fieldName={field.name}
                  isRequired={field.is_required || false}
                  mask={'+7 (999) 999-99-99'}
                  error={errors[field.name]}
                  value={values[field.name]}
                  onChange={onChange(field.name)}
                  danger={!!errors[field.name]}
                  isDisabled={isFormDisabled}
                />
              )
            case 'gender':
              return (
                <RadioList
                  key={field.name}
                  data={genderData}
                  fieldName={field.name}
                  isRequired={field.is_required || false}
                  onChange={onChangeGender}
                  currentValue={values.gender}
                  isDisabled={isFormDisabled}
                />
              )
            case 'has_young_children':
              return (
                <RadioList
                  key={field.name}
                  data={hasYoungChildrenData}
                  fieldName={field.name}
                  isRequired={field.is_required || false}
                  onChange={onChangeChildren}
                  currentValue={values.has_young_children}
                  isDisabled={isFormDisabled}
                />
              )
            case 'source':
              return (
                <CustomSelect
                  fieldName={field.name}
                  key={field.name}
                  options={sourceOptions}
                  value={values[field.name]}
                  onSelectChange={onChangeSource}
                  isRequired={field.is_required || false}
                  isDisabled={isFormDisabled}
                />
              )
            case 'passport_number':
              return (
                <PassportBlock
                  key={field.name}
                  onChange={onChange}
                  isRequired={field.is_required || false}
                  values={values}
                  errors={errors}
                  setErrors={setErrors}
                  isDisabled={isFormDisabled}
                />
              )
            default:
              return (
                !passportFields.includes(field.name) && (
                  <CustomInput
                    key={field.name}
                    fieldName={field.name}
                    onChange={onChange(field.name)}
                    isRequired={field.is_required || false}
                    value={values[field.name]}
                    error={errors[field.name]}
                    danger={!!errors[field.name]}
                    isDisabled={isFormDisabled}
                  />
                )
              )
          }
        })}

        {isShowCheckboxes && (
          <>
            <div className={styles.checkBoxWrapper}>
              {areaInfo.link_processing_personal_data && (
                <CheckBox
                  disabled={isFormDisabled}
                  name="dataProcessing"
                  checked={dataProcessing}
                  title={`<span>Выражаю согласие на <a href=${areaInfo.link_processing_personal_data} target='_blank'>обработку персональных данных</a></span>`}
                  onChange={onChangeStateLinks('personalData')}
                />
              )}
              {areaInfo.link_parking_rules && (
                <CheckBox
                  disabled={isFormDisabled}
                  name="rulesUsingParking"
                  checked={rulesUsingParking}
                  title={`<span>Подтверждаю, что ознакомлен с <a href=${areaInfo.link_parking_rules} target='_blank'>правилами пользования парковкой</a></span>`}
                  onChange={onChangeStateLinks('parkingRules')}
                />
              )}
            </div>

            <div className={styles.buttonsWrapper}>
              {isShowonCancelEditingButton && (
                <Button onClick={onCancelEditing} className={styles.cancelButton}>
                  Отменить
                </Button>
              )}
              <Button
                disabled={isSaveButtonDisabled}
                onClick={onFormSubmit}
                className={styles.saveButton}
                loading={savingSubscriptionInfo}
                withLoader
              >
                Сохранить
              </Button>
            </div>
          </>
        )}
      </div>
    </section>
  )
}
