import { Title, Container, Paragraph, Button, FixedBottom, HalvaBanner } from '@/components'
import styles from './PaymentResult.module.scss'
import cn from 'classnames'
import getFullDate from '@/helpers/getFullDate'
import getLinkHref from '@/helpers/getLinkHref'
import Link from 'next/link'
import { AreaInfoResponseData } from '@/store/slices/stagesSlice/interfaces'

export default function NoPaymentRequired({
  paidUntil,
  area,
}: {
  paidUntil?: string | null
  area: AreaInfoResponseData | null
}) {
  const areaHref = getLinkHref({ type: 'area', data: { id: area?.id } })
  const date = getFullDate(paidUntil || '', true)
  const paidUntilText = date
    ? `\nдо ${date.day}.${date.month}.${date.year} ${date.hours}:${date.minutes}:${date.seconds}`
    : ''

  return (
    <>
      <div>
        <section className={styles.section}>
          <Container className={styles.container}>
            <HalvaBanner />
            <img
              className={styles.icon}
              src="/images/payment-success.svg"
              alt="logo"
              width={87}
              height={87}
            />

            <Title align="center">Оплата не требуется!</Title>

            <div className={styles.section__text_content}>
              {date && (
                <Paragraph align="center" className={styles.section__descr}>
                  Вы можете выехать с парковки {paidUntilText}.
                </Paragraph>
              )}
              <Paragraph align="center" className={styles.section__text}>
                Держите номер авто чистым для автоматического открытия шлагбаума.
              </Paragraph>
            </div>
            <FixedBottom>
              <div className={styles.button}>
                <Link href={areaHref} passHref>
                  <Button
                    component="a"
                    className={styles.button}
                    background={area?.customising?.button_back_color}
                  >
                    На главную
                  </Button>
                </Link>
              </div>
              <div className={cn(styles.footerMessage, styles.footerMessageWrapper)}>
                Номер технической поддержки сервиса HippoParking:{' '}
                <a className={styles.footerMessage_isDesktop} href={'tel:88002003320'}>
                  {'8 800 200 33 20'}
                </a>
                <a className={styles.footerMessage_isTablet} href={'tel:88002003320'}>
                  {'8 800 200 33 20'}
                </a>
              </div>
            </FixedBottom>
          </Container>
        </section>
      </div>
    </>
  )
}
