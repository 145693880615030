import { Title, Container, Paragraph, Card, Button } from '@/components'
import { getAreaInfoById, getSubscriptionsByAreaId } from '@/store/slices/stagesSlice/stagesSlice'
import Link from 'next/link'
import getLinkHref from '@/helpers/getLinkHref'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { IAreaSubscription } from '@/store/slices/stagesSlice/interfaces'
import formatNumberToString from '@/helpers/formatNumberToString'
import unicode from '@/helpers/unicodeChars'
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles'
import getSubscriptionInterval from '@/helpers/getSubscriptionInterval'

import styles from './SubscriptionsPage.module.scss'

export default function SubscriptionsPage({ areaId }: { areaId: string }) {
  useAreaStyles(areaId)
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data } = getSubscriptionsByAreaId(reduxState, areaId)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId as string)

  return (
    <section className={styles.section}>
      <Container>
        <Title className={styles.section__title}>Выберите тариф</Title>
        <Paragraph className={styles.section__descr}>
          Абонемент начнет действовать после оплаты.
        </Paragraph>

        {data && data.length > 0 ? (
          <ul className={styles.list}>
            {data?.map(subscription => (
              <li key={subscription.id}>
                <Subscription
                  {...subscription}
                  areaId={areaId}
                  cardColor={areaInfo?.customising.cards_color}
                />
              </li>
            ))}
          </ul>
        ) : (
          <Card color="decor" cardColor={areaInfo?.customising.cards_color}>
            <div className={styles.notFound}>
              <div className={styles.notFound_Image}>
                <img src="/images/subscription-not-found.svg" alt="" />
              </div>
              <span>Нет доступных абонементов</span>
            </div>
          </Card>
        )}
      </Container>
    </section>
  )
}

interface SubscriptionProps extends IAreaSubscription {
  areaId: string
  cardColor?: string | null
}

export function Subscription({
  areaId,
  id,
  interval_type,
  interval,
  name,
  description,
  amount,
  balance,
  cardColor,
  available_count,
  type_work,
  fixed_period_from,
  fixed_period_to,
}: SubscriptionProps) {
  const disabled = balance < 1
  const price = `${formatNumberToString(+amount)}${unicode.no_break_space}${unicode.ruble}`

  const subscriptionHref = getLinkHref({
    type: 'subscription-number',
    data: { areaId, subscriptionId: id },
  })

  const subscriptionInterval = getSubscriptionInterval({
    type_work,
    interval_type,
    interval,
    fixed_period_from,
    fixed_period_to,
  })

  return (
    <Card color="accent" className={styles.subscription} cardColor={cardColor}>
      <div className={styles.subscription_Content}>
        <header className={styles.subscription_Header}>
          <h2 className={styles.subscription_Title}>{name}</h2>
          <div className={styles.subscription_Header_data}>
            <span className={styles.subscription_Interval}>{subscriptionInterval}</span>
            <p>Доступно: {available_count}</p>
          </div>
        </header>
        <p className={styles.subscription_Subtitle}>Что входит в абонемент:</p>
        {description && <p className={styles.subscription_Desc}>{description}</p>}

        {disabled ? (
          <Button disabled className={styles.subscription_Button} color="secondary" type="button">
            Нет мест
          </Button>
        ) : (
          <Link href={subscriptionHref} passHref>
            <Button component="a" className={styles.subscription_Button} color="secondary">
              {price}
            </Button>
          </Link>
        )}
      </div>
    </Card>
  )
}
