import { Dispatch, SetStateAction } from 'react'
import styles from './PromoModal.module.scss'
import { PartnerPromotion } from '@/store/slices/stagesSlice/interfaces'
import { Icon } from '@/components'

export default function PromoModal({
  setIsOpen,
  partnerPromotion,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  partnerPromotion: PartnerPromotion
}) {
  const {
    partner_name,
    partner_additional_info,
    partner_license_url,
    partner_license_name,
    partner_conditions_name,
    partner_conditions_url,
  } = partnerPromotion

  const closeModal = () => setIsOpen(false)

  const isShowLicenseLink = partner_license_name && partner_license_url
  const isShowConditionsLink = partner_conditions_name && partner_conditions_url

  return (
    <div className={styles.overlay} onClick={closeModal}>
      <div className={styles.modal} onClick={event => event.stopPropagation()}>
        <button className={styles.modal__closeButton} onClick={closeModal}>
          <Icon name="close-icon" />
        </button>
        <h2 className={styles.modal__title}>Реклама</h2>
        <h3 className={styles.modal__subtitle}>{partner_name}</h3>
        <p className={styles.modal__paragraph}>{partner_additional_info}</p>
        {isShowLicenseLink && (
          <a className={styles.modal__link} href={partner_license_url} target="_blank">
            {partner_license_name}
          </a>
        )}
        {isShowConditionsLink && (
          <a className={styles.modal__link} href={partner_conditions_url} target="_blank">
            {partner_conditions_name}
          </a>
        )}
      </div>
    </div>
  )
}
