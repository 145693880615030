import * as React from 'react'
const Svg = () => (
  <svg
    width="249"
    height="250"
    viewBox="0 0 249 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_36_186"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="249"
      height="250"
    >
      <g clip-path="url(#clip0_36_186)">
        <path
          d="M138.504 34.0685L60.0494 59.1932C53.55 61.2746 49.9686 68.2307 52.05 74.7301L94.7619 208.103C96.8433 214.602 103.799 218.184 110.299 216.102L188.753 190.978C195.253 188.896 198.834 181.94 196.753 175.441L154.041 42.068C151.959 35.5686 145.003 31.9871 138.504 34.0685Z"
          stroke="white"
          stroke-width="11.8935"
        />
        <path
          d="M98.969 72.6797L114.66 67.6547M126.298 185.028L157.68 174.979"
          stroke="white"
          stroke-width="11.8935"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M147.993 129.928L141.064 107.264C139.797 103.119 135.391 100.787 131.227 102.061L106.639 109.578C102.474 110.851 100.124 115.245 101.392 119.392L108.322 142.057C109.478 145.841 113.249 148.112 117.064 147.51C117.429 147.454 117.795 147.371 118.16 147.259L142.746 139.742C143.11 139.631 143.46 139.496 143.794 139.338C147.294 137.703 149.151 133.713 147.993 129.928ZM123.269 117.436L117.823 119.101L117.824 119.102L122.647 134.879L126.231 133.783L124.594 128.429L126.554 127.83C129.913 126.803 132.256 124.294 131.122 120.585C129.95 116.751 126.756 116.37 123.269 117.436ZM127.61 121.659C128.112 123.302 127.447 124.461 125.476 125.064L123.728 125.598L122.272 120.837L123.922 120.332C125.863 119.739 127.105 120.007 127.61 121.659Z"
          fill="white"
        />
      </g>
    </mask>
    <g mask="url(#mask0_36_186)">
      <rect y="1" width="249" height="248" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_36_186">
        <rect
          width="197.711"
          height="197.711"
          fill="white"
          transform="translate(0.106934 61.0898) rotate(-17.7574)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default Svg
