import {Button, Card, Container} from '@/components'
import {getAreaInfoById, getEventsByAreaId} from '@/store/slices/stagesSlice/stagesSlice'
import Link from 'next/link'
import getLinkHref from '@/helpers/getLinkHref'
import {useSelector} from 'react-redux'
import {RootState} from '@/store'
import {IAreaEvent} from '@/store/slices/stagesSlice/interfaces'
import formatNumberToString from '@/helpers/formatNumberToString'
import unicode from '@/helpers/unicodeChars'
import cn from 'classnames'
import styles from './EventsPage.module.scss'
import {format} from 'date-fns'
import ru from 'date-fns/locale/ru'

export default function EventsPage({ areaId }: { areaId: string }) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: events } = getEventsByAreaId(reduxState, areaId)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId as string)

  return (
    <section className={styles.section}>
      <Container>

        {!!events?.length ? (
          <ul className={styles.list}>
            {events?.map(event => (
              <li key={event.id}>
                <Event
                  {...event}
                  areaId={areaId}
                  cardColor={
                    areaInfo?.customising.cards_color ??
                    'linear-gradient(180deg, #3879F0 0%, #428EFF 100%)'
                  }
                />
              </li>
            ))}
          </ul>
        ) : (
          <Card
            cardColor={
              areaInfo?.customising.cards_color ??
              'linear-gradient(180deg, #3879F0 0%, #428EFF 100%)'
            }
          >
            <div className={styles.notFound}>
              <div className={styles.notFound_Image}>
                <img src="/images/subscription-not-found.svg" alt="" />
              </div>
              <span>Нет доступных мероприятий</span>
            </div>
          </Card>
        )}
      </Container>
    </section>
  )
}

interface EventProps extends IAreaEvent {
  areaId: string
  cardColor?: string | null
  description?: string | null
}

function Event({ areaId, id, name, amount, cardColor, start_at, end_at, description }: EventProps) {
  const price = `${formatNumberToString(+amount)}${unicode.no_break_space}${unicode.ruble}`
  const eventHref = getLinkHref({
    type: 'event-number',
    data: { areaId, eventId: id },
  })

  return (
    <Card
      color="accent"
      className={cn(styles.subscription, styles.eventCard)}
      cardColor={cardColor}
    >
      <div className={styles.subscription_Content}>
        <div className={styles.subscription_Header}>
          <h2 className={styles.subscription_Title}>{name}</h2>
          <span className={styles.subscription_Interval}>
            {format(new Date(start_at), 'dd MMMM', { locale: ru })}{' '}
            {format(new Date(start_at), 'HH:mm', { locale: ru })}-
            {format(new Date(end_at), 'HH:mm', { locale: ru })}
          </span>
        </div>
        {description && <div className={styles.description}>{description}</div>}
        <Link href={eventHref} passHref>
          <Button component="a" className={styles.subscription_Button} color="secondary">
            {price}
          </Button>
        </Link>
      </div>
    </Card>
  )
}
