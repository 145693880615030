import React, { ReactNode } from 'react'
import cn from 'classnames'

import styles from './DropContainer.module.scss'

interface Props {
  children: ReactNode
  isVisible?: boolean
  className?: string
}

const DropContainer = ({ children, isVisible, className }: Props) => {
  return (
    <div className={cn(styles.container, className, { [styles['container--visible']]: isVisible })}>
      {children}
    </div>
  )
}

export default DropContainer
