import { useLayoutEffect, useState } from 'react'
import { useRouter } from 'next/router'
import CarNumberPage from '../_common/CarNumberPage'
import getLinkHref from '@/helpers/getLinkHref'
import { setCookie } from 'cookies-next'
import { NUMBER_CAR } from '@/constants'
import { getFormattedCarNumber } from '@/helpers/getFormattedCarNumber'

export default function EventNumberPage({ areaId, eventId }: { areaId: string; eventId: string }) {
  const router = useRouter()
  const [value, setValue] = useState('')

  const onSubmit = async () => {
    const number = value.replace(/\s/g, '')

    if (number.length <= 2) return

    const paymentHref = getLinkHref({
      type: 'event-payment',
      data: { areaId, eventId, number },
    })
    router.push(paymentHref)
    setCookie(NUMBER_CAR, number)
  }

  useLayoutEffect(() => {
    const number = getFormattedCarNumber()
    setValue(number)
  }, [])

  return <CarNumberPage value={value} onChange={setValue} onSubmit={onSubmit} areaId={areaId} />
}
