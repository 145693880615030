import CustomInput from './CustomInput'
import CustomMaskInput from './CustomMaskInput'
import { FormErrors, FormValues } from '../SubscriptionPageForm'
import { Dispatch, SetStateAction } from 'react'
import styles from '../SubscriptionFieldsPage.module.scss'
import { AdditionalFieldNames } from '@/store/slices/stagesSlice/interfaces'

const PassportBlock = ({
  onChange,
  isRequired,
  values,
  errors,
  setErrors,
  isDisabled,
}: {
  onChange: (field: AdditionalFieldNames) => (event: React.ChangeEvent<HTMLInputElement>) => void
  isRequired: boolean
  values: FormValues
  errors: FormErrors
  setErrors: Dispatch<SetStateAction<FormErrors>>
  isDisabled: boolean
}) => {
  return (
    <div>
      <CustomInput
        fieldName="passport_number"
        onChange={onChange('passport_number')}
        isRequired={isRequired}
        value={values.passport_number}
        error={errors.passport_number}
        danger={!!errors.passport_number}
        key="passport_number"
        isDisabled={isDisabled}
      />
      <div className={styles.shortInputs}>
        <CustomMaskInput
          setErrors={setErrors}
          fieldName="passport_date"
          isRequired={isRequired}
          mask="99/99/9999"
          error={errors.passport_date}
          value={values.passport_date}
          onChange={onChange('passport_date')}
          type="date"
          key="passport_date"
          danger={!!errors.passport_date}
          isDisabled={isDisabled}
        />
        <CustomInput
          fieldName="passport_who_gave"
          onChange={onChange('passport_who_gave')}
          isRequired={isRequired}
          value={values.passport_who_gave}
          error={errors.passport_who_gave}
          danger={!!errors.passport_who_gave}
          key="passport_who_gave"
          isDisabled={isDisabled}
        />
      </div>
    </div>
  )
}

export default PassportBlock
