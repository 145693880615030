import { initializeStore } from '@/store'
import { fetchPaymentReceipt } from '@/store/slices/stagesSlice/stagesSlice'
import { useEffect, useState } from 'react'

type UseFetchPaymentReceiptArgs = {
  orderNumber: string
  timer: number
  receipt_link?: string | null
  ofd_url?: string | null
}

export default function useFetchPaymentReceipt({
  orderNumber,
  timer,
  receipt_link,
  ofd_url,
}: UseFetchPaymentReceiptArgs) {
  const reduxStore = initializeStore()
  const { dispatch } = reduxStore
  const [fetchCount, setFetchCount] = useState<number>(0)
  const isRepeatFetch = fetchCount && fetchCount < 7 && timer && !receipt_link && !ofd_url

  const fetchDataAndIncreaseCount = () => {
    dispatch(fetchPaymentReceipt({ orderNumber }))
    setFetchCount(prev => ++prev)
  }

  useEffect(() => {
    fetchDataAndIncreaseCount()
  }, [])

  useEffect(() => {
    if (isRepeatFetch) {
      setTimeout(() => fetchDataAndIncreaseCount(), 10000)
    }
  }, [fetchCount])
}
