import { PaymentStatus } from '@/components/pages/PaymentReceiptPage/types'
import { usePaymentSteps } from '@/components/pages/PaymentReceiptPage/hooks'
import cn from 'classnames'
import styles from '../PaymentReceiptPage.module.scss'

export default function PaymentSteps({ paymentStatus }: { paymentStatus: PaymentStatus }) {
  const activeStep = usePaymentSteps({ paymentStatus })
  const defineIsActiveClassName = (step: number) => {
    return step <= activeStep
  }

  return (
    <ul className={styles.stepsList}>
      {[1, 2, 3].map(step => {
        return (
          <li className={styles.stepsListItem} key={step}>
            <span
              className={cn(styles.stepNumber, {
                [styles.stepNumber_active]: defineIsActiveClassName(step),
              })}
            >
              {step}
            </span>
            <span
              className={cn(styles.stepLine, {
                [styles.stepLine_active]: defineIsActiveClassName(step),
              })}
            />
          </li>
        )
      })}
    </ul>
  )
}
