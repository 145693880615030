import { useState } from 'react'
import { useSelector } from 'react-redux'
import { initializeStore, RootState } from '@/store'
import {
  fetchPurchasedSubscriptionInfo,
  getPurchasedSubscriptionInfo,
  getSavePurchasedSubscription,
} from '@/store/slices/stagesSlice/stagesSlice'
import getLinkHref from '@/helpers/getLinkHref'
import SubscriptionPageHeader from './SubscriptionPageHeader'
import SubscriptionPageForm from './SubscriptionPageForm'
import SubscriptionPageInfo from './SubscriptionPageInfo'
import styles from './SubscriptionFieldsPage.module.scss'
import Modal from '@/components/Modal'
import SubscriptionNotFoundPage from './SubscriptionNotFoundPage'

export default function SubscriptionFieldsPage({
  areaId,
  token,
}: {
  areaId: string
  token: string
}) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const reduxStore = initializeStore()
  const { dispatch } = reduxStore

  const { data: subscriptionInfo, error } = getPurchasedSubscriptionInfo(reduxState)
  const { loading: savingSubscriptionInfo } = getSavePurchasedSubscription(reduxState)
  const areaInfo = subscriptionInfo?.area
  const clientSubscription = subscriptionInfo?.client_subscription
  const additionalFields = subscriptionInfo?.additional_fields
  const areaHref = getLinkHref({ type: 'area', data: { id: areaId } })

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false)

  if (error) {
    return (
      <SubscriptionNotFoundPage areaHref={areaHref} />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <SubscriptionPageHeader areaHref={areaHref} areaName={areaInfo?.name ?? ''} />
        <main className={styles.main}>
          {areaInfo && additionalFields && (
            <SubscriptionPageForm
              token={token}
              areaInfo={areaInfo}
              additionalFieldsData={additionalFields}
              setIsSuccessModalOpen={setIsSuccessModalOpen}
              setIsErrorModalOpen={setIsErrorModalOpen}
              savingSubscriptionInfo={savingSubscriptionInfo}
            />
          )}
          {areaInfo && clientSubscription && (
            <SubscriptionPageInfo
              areaHref={areaHref}
              areaInfo={areaInfo}
              clientSubscription={clientSubscription}
            />
          )}
        </main>
      </div>
      {isSuccessModalOpen && (
        <Modal
          setIsOpen={setIsSuccessModalOpen}
          message="Ваши данные успешно сохранены"
          type="success"
          key="success"
          onConfirm={() => {
            dispatch(fetchPurchasedSubscriptionInfo({ token: token }))
          }}
        />
      )}
      {isErrorModalOpen && (
        <Modal
          setIsOpen={setIsErrorModalOpen}
          message="Произошел технический сбой. Повторите попытку позже"
          type="warning"
          key="error"
        />
      )}
    </div>
  )
}
