import cn from 'classnames'
import styles from './Card.module.scss'

type CardColor = 'primary' | 'accent' | 'decor'

export default function Card({
  color = 'primary',
  className,
  children,
  cardColor,
}: {
  color?: CardColor
  className?: string
  children: React.ReactNode
  cardColor?: string | null
}) {
  return (
    <div
      className={cn(styles.card, className, {
        [styles.card__Accent]: color === 'accent',
        [styles.card__Decor]: color === 'decor',
      })}
      style={{ background: cardColor ? cardColor : '' }}
    >
      {children}
    </div>
  )
}
