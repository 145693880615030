const unicodeChars = {
  ruble: '\u20BD',
  squared: '\u00b2',
  minus: '\u2212',
  endash: '\u2013',
  emdash: '\u2014',
  enspace: '\u2002',
  emspace: '\u2003',
  copyright: '\u00a9',
  left_double_angle_quote: '\u00AB',
  right_double_angle_quote: '\u00BB',
  left_double_quote: '\u201C',
  circle: '\u2981',
  dot: '\u22C5',
  diamond: '\u22C4',
  no_break_space: '\u00A0',
}

export default unicodeChars
