import { memo, forwardRef, useRef, useCallback, ChangeEventHandler } from 'react'
import { RadioImgProps } from './interfaces'
import styles from './Choice.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const RadioImg = memo(
  forwardRef((props: RadioImgProps, inputRef) => {
    const { value, checked, onChange, disabled = false, name, img, alt = '', ...rest } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        onChange(e.target.value)
      },
      [onChange],
    )

    return (
      <label className={cn(styles.choice, styles.radio_img)} {...rest}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={_onChange}
          disabled={disabled}
          className={styles.input}
          ref={mergeRefs([ref, inputRef])}
        />

        <span className={styles.title}>
          <span className={styles.img}>
            {img ? <img src={img.largePreview} alt={name} /> : <span className={styles.alt}>{alt}</span>}
          </span>
        </span>
      </label>
    )
  }),
)

export default RadioImg
