export const passportFields = ['passport_number', 'passport_date', 'passport_who_gave']
export const LINK_PERSONAL_DATA = 'link_processing_personal_data'
export const LINK_PARKING_RULES = 'link_parking_rules'

export const orderArray = [
  'full_name',
  'address',
  'birthday',
  'phone',
  'gender',
  'has_young_children',
  'passport_number',
  'car_brand',
  'car_color',
  'organization',
  'source',
]

export const genderData = [
  {
    name: 'gender',
    value: 'male',
    title: 'Мужской',
  },
  {
    name: 'gender',
    value: 'female',
    title: 'Женский',
  },
]

export const hasYoungChildrenData = [
  {
    name: 'has_young_children',
    value: 'yes',
    title: 'Да',
  },
  {
    name: 'has_young_children',
    value: 'no',
    title: 'Нет',
  },
]

export const sourceOptions = [
  {
    title: 'Наружная реклама',
    value: 'advertising',
  },
  {
    title: 'Авито',
    value: 'avito',
  },
  {
    title: 'СМС рассылка',
    value: 'sms',
  },
  {
    title: 'Интернет',
    value: 'internet',
  },
]
