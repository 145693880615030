import { useLayoutEffect } from 'react'

interface IAreasStyles {
  [key: string]: {
    [key: string]: string
  }
}

const areasStyles: IAreasStyles = {
  kashirskaya_plaza: {
    '--bg-partner-card': '#2664A1',
    '--bg-payment-card': '#004990',
    '--bg-subscription-card': '#2664A1',
    '--bg-event-card': '#2664A1',
    '--bg-subscription-card3n1': '#2664A1',
    '--bg-subscription-card3n2': '#2664A1',
    '--bg-subscription-card3n3': '#2664A1',
    '--bg-subscription-single-card': '#2664A1',
    '--clr-bg-primary': '#004990',
    '--icon-position-top-payment-card': '-20px',
    '--icon-position-right-payment-card': '0',
    '--icon-width-payment-card': '180px',
    '--icon-position-bottom-subscription-card': '-20px',
    '--icon-position-right-subscription-card': '-40px',
  },
}

export const useAreaStyles = (slug: string) => {
  useLayoutEffect(() => {
    if (slug in areasStyles) {
      for (let styleProperty in areasStyles[slug]) {
        document.documentElement.style.setProperty(styleProperty, areasStyles[slug][styleProperty])
      }
    }
  }, [])
}
