import { Title, Button, CopyText } from '@/components'
import { PaymentReceiptField } from '@/store/slices/stagesSlice/interfaces'
import styles from '../PaymentReceiptPage.module.scss'

export default function ReceiptFields({
  ofdUrl,
  receiptFields,
}: {
  ofdUrl: string
  receiptFields: PaymentReceiptField[]
}) {
  return (
    <div>
      <Title level={2} className={styles.subtitle}>
        Ваш чек готов
      </Title>
      <p className={styles.description}>
        Чек можно получить на сайте ОФД. Для получения чека используйте данные о платеже.
      </p>
      <ul>
        {!!receiptFields.length &&
          receiptFields.map((paymentDetail: PaymentReceiptField) => {
            return (
              <InfoListItem
                title={paymentDetail.title}
                value={paymentDetail.value}
                key={paymentDetail.title}
              />
            )
          })}
      </ul>
      {ofdUrl && (
        <a href={ofdUrl} target="_blank">
          <Button className={styles.button}>Перейти на сайт ОФД</Button>
        </a>
      )}
    </div>
  )
}

function InfoListItem({ title, value }: { title: string; value: string | number }) {
  return (
    <li className={styles.infoListItem}>
      <Title level={3} className={styles.infoListItemTitle}>
        {title}
      </Title>
      <div className={styles.infoListItemTextWrapper}>
        <p className={styles.infoListItemText}>{value}</p>
        <CopyText value={String(value)} />
      </div>
    </li>
  )
}
