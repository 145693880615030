import { IconProps } from './interfaces'

const duration = '500ms'

const SVG = ({
  fill = 'currentColor',
  width = '64',
  height = '64',
  viewBox = '0 0 64 64',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    stroke={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="4" strokeLinecap="round">
      <line y1="12" y2="20" transform="translate(32,32) rotate(180)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(210)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values="0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(240)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".1;0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(270)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".15;.1;0;1;.85;.7;.65;.55;.45;.35;.25;.15"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(300)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".25;.15;.1;0;1;.85;.7;.65;.55;.45;.35;.25"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(330)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".35;.25;.15;.1;0;1;.85;.7;.65;.55;.45;.35"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(0)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".45;.35;.25;.15;.1;0;1;.85;.7;.65;.55;.45"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(30)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".55;.45;.35;.25;.15;.1;0;1;.85;.7;.65;.55"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(60)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".65;.55;.45;.35;.25;.15;.1;0;1;.85;.7;.65"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(90)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".7;.65;.55;.45;.35;.25;.15;.1;0;1;.85;.7"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(120)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values=".85;.7;.65;.55;.45;.35;.25;.15;.1;0;1;.85"
          repeatCount="indefinite"
        ></animate>
      </line>
      <line y1="12" y2="20" transform="translate(32,32) rotate(150)">
        <animate
          attributeName="stroke-opacity"
          dur={duration}
          values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
          repeatCount="indefinite"
        ></animate>
      </line>
    </g>
  </svg>
)

export default SVG
