import Text from '@/components/Typography/Text/Text'
import styles from './PaymentPage.module.scss'
import cn from 'classnames'

const CarNumberLabel = ({ number, className }: { number: string; className?: string }) => {
  return (
    <Text level={1} className={cn(styles['car-number'], className)}>
      {number}
    </Text>
  )
}

export default CarNumberLabel
