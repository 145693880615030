import { LinkProps, isLinkType } from './interfaces'
import { ActiveLink } from '@/components'
import styles from './Link.module.scss'
import cn from 'classnames'

export default function Link(props: LinkProps) {
  if (isLinkType(props)) {
    const { href, className, children, ...rest } = props
    return (
      <ActiveLink
        className={cn(styles.link, className)}
        activeClass={styles.active}
        {...rest}
        href={props.href}
      >
        <a>{children}</a>
      </ActiveLink>
    )
  } else {
    const { button, className, children, ...rest } = props
    return (
      <button className={cn(styles.link, className)} {...rest}>
        {children}
      </button>
    )
  }
}
