export default function getDate(seconds: number) {
  const m = Math.floor((seconds % 3600) / 60)
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const d = Math.floor(seconds / (3600 * 24))
  const s = Math.floor(seconds % 60)

  return {
    seconds: s,
    minutes: m,
    hours: h,
    days: d,
  }
}
