import styles from './IndexPage.module.scss'

const IndexPage = () => {
  return (
    <section className={styles.section}>
      <div className={styles.plug}>
        <img src="/images/index_plug.png" alt="" />
        <span className={styles.plug_text}>
          Оплата парковки <br /> в твоем телефоне
        </span>
      </div>
      <div className={styles.image}>
        <img src="https://i.ibb.co/KzcRGwD/image-444.png" alt="bank services" />
      </div>
    </section>
  )
}

export default IndexPage
