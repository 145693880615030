import { Title, Container, FixedBottom, Button, Paragraph, QrScanner } from '@/components'
import { useState, useCallback } from 'react'
import { getOpenExitGateState, openExitGate } from '@/store/slices/stagesSlice/stagesSlice'
import styles from './OpenExitGatePage.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { QrScannerState } from '@/components/QrScanner/QrScanner'
import { RootState } from '@/store'
import cn from 'classnames'

export default function OpenExitGatePage({ stageId }: { stageId: string }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const [scanner, setScanner] = useState<{ state: QrScannerState; result: string | null }>({
    state: 'scanning',
    result: null,
  })
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { loading, error } = getOpenExitGateState(reduxState)
  const qrError = error?.qr ? error.qr[0] : error?.catched ? error.catched[0] : null

  const onScan = useCallback(async (result: string) => {
    setScanner({ state: 'success', result })

    await dispatch(
      openExitGate({
        stageId,
        gateCode: result,
        onError: () => {
          setScanner(prev => ({ ...prev, state: 'scanning' }))
        },
      }),
    )
  }, [])

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Title>Отсканируйте QR-код шлагбаума</Title>
        <Paragraph className={styles.section__text}>
          Вы можете найти его на стойке у выездного шлагбаума.
        </Paragraph>
        <Paragraph className={styles.section__text}>
          Убедитесь, что вы предоставили доступ к камере для этой страницы.
        </Paragraph>

        <div className={styles.scanner}>
          <QrScanner state={scanner.state} onScanSuccess={onScan} />
        </div>

        {!!scanner.result && (
          <div className={cn(styles.scanInfo, { [styles.scanInfo__Error]: !!qrError })}>
            {loading ? 'Открываем проезд...' : !!qrError ? qrError : 'Успешно!'}
          </div>
        )}

        <FixedBottom>
          <div className={styles.button}>
            <Button type="button" onClick={router.back}>
              Назад
            </Button>
          </div>
        </FixedBottom>
      </Container>
    </section>
  )
}
