import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const { host } = publicRuntimeConfig || {}

type GetHostReq = { headers: { host?: string } } | undefined

export const getHost = (req: GetHostReq) => {
  return process.env.NODE_ENV === 'development' ? host : req ? `${req.headers.host}` : location.host
}
