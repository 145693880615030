import { Title, Container, Button, RadioImg, FixedBottom } from '@/components'
import styles from './ValidateCarPage.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchPaymentInfo,
  getAreaInfoById,
  getPaymentInfoById,
  getCarsByNumber,
  getSelectedId,
  changeSelectedId,
} from '@/store/slices/stagesSlice/stagesSlice'
import getLinkHref from '@/helpers/getLinkHref'
import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { RootState } from '@/store'
import { getNumberLink } from '@/helpers/getNumberLink'

export default function ValidateCarPage({ number, areaId }: { number: string; areaId: string }) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const dispatch = useDispatch()
  const router = useRouter()
  const { data } = getCarsByNumber(reduxState, number)

  const selectedId = getSelectedId(reduxState)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId)
  const phoneLink = getNumberLink(areaInfo?.phone)

  const { loading } = getPaymentInfoById(reduxState, null)

  const onFieldChange = (value: string) => {
    dispatch(changeSelectedId({ id: +value ?? null }))
  }

  const _fetchPaymentInfo = useCallback(async () => {
    if (selectedId === null) return
    const notFoundHref = getLinkHref({ type: 'car-number-not-found', data: { id: areaId } })
    const activeSessionHref = getLinkHref({
      // type: 'active-session',
      type: 'payment',
      data: { areaId, stageId: selectedId },
    })

    await dispatch(
      fetchPaymentInfo({
        id: selectedId,
        number: number,
        onSuccess: () => router.push(activeSessionHref),
        onError: () => router.push(notFoundHref),
      }),
    )
  }, [selectedId, number])

  const submitDisabled = (data || []).every(item => item.id !== selectedId)

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Title>Выберите фото с вашей машиной</Title>

        <div className={styles.radioButtons}>
          {data?.map(item => {
            const { id, photo, number } = item
            const value = `${id}`

            return (
              <div key={id} className={styles.radioButtons__item}>
                <RadioImg
                  name="photo"
                  value={value}
                  checked={selectedId === +value}
                  img={photo}
                  alt={number}
                  onChange={onFieldChange}
                />
              </div>
            )
          })}
        </div>

        <FixedBottom>
          <footer className={styles.footer}>
            <Button
              disabled={submitDisabled}
              onClick={_fetchPaymentInfo}
              withLoader
              loading={loading}
              className={styles.button}
              background={areaInfo?.customising.button_back_color}
            >
              Продолжить
            </Button>
            {phoneLink && (
              <Button component="a" href={phoneLink} className={styles.button} isTransparent>
                Обратиться в поддержку
              </Button>
            )}
          </footer>
        </FixedBottom>
      </Container>
    </section>
  )
}
