import { Radio } from '@/components'
import styles from '../SubscriptionFieldsPage.module.scss'
import { getLabelText } from '../utils'

const RadioList = ({
  data,
  onChange,
  currentValue,
  fieldName,
  isRequired,
  isDisabled,
}: {
  data: Array<{ [key: string]: string }>
  onChange: any
  currentValue: string
  fieldName: string
  isRequired: boolean
  isDisabled: boolean
}) => {
  const label = `${getLabelText(fieldName)} ${isRequired ? '*' : ''}`

  return (
    <div className={styles.radioList}>
      <div className={styles.label}>{label}</div>
      <div className={styles.radioListItems}>
        {data?.map(({ name, value, title }) => (
          <Radio
            disabled={isDisabled}
            className={styles.radio}
            key={value}
            name={name}
            value={value}
            checked={currentValue === value}
            title={title}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioList
