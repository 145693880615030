import { fetchCars, getCarsByNumber } from '@/store/slices/stagesSlice/stagesSlice'
import CarNumberPage from '../_common/CarNumberPage'
import { useSelector, useDispatch } from 'react-redux'
import { useLayoutEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { RootState } from '@/store'
import getLinkHref from '@/helpers/getLinkHref'
import { setCookie } from 'cookies-next'
import { NUMBER_CAR } from '@/constants'
import { getFormattedCarNumber } from '@/helpers/getFormattedCarNumber'

export default function FindCarNumberPage({ areaId }: { areaId: string }) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const dispatch = useDispatch()
  const router = useRouter()

  const [value, setValue] = useState('')
  const { loading, error } = getCarsByNumber(reduxState, value)
  const numberError = error?.number?.[0]

  const onSubmit = async () => {
    const number = value.replace(/\s/g, '')
    if (number.length <= 2) return

    const notFoundHref = getLinkHref({ type: 'car-number-not-found', data: { id: areaId } })
    const validateCarHref = getLinkHref({ type: 'validate-car', data: { id: areaId, number } })

    await dispatch(
      fetchCars({
        number,
        areaId,
        onSuccess: data => {
          if (data?.length === 0) {
            router.push(notFoundHref)
          } else if (data?.length === 1 && data?.[0].similarity === 1) {
            const stageId = data?.[0].id
            const activeSessionHref = getLinkHref({
              type: 'payment',
              data: { areaId, stageId },
            })
            router.push(activeSessionHref)
          } else {
            router.push(validateCarHref)
          }
          setCookie(NUMBER_CAR, number)
        },
        onError: error => {
          if (error.catched) {
            router.push(validateCarHref)
          }
        },
      }),
    )
  }

  useLayoutEffect(() => {
    const number = getFormattedCarNumber()
    setValue(number)
  }, [])

  return (
    <CarNumberPage
      value={value}
      onChange={setValue}
      onSubmit={onSubmit}
      loading={loading}
      error={numberError}
      areaId={areaId}
    />
  )
}
