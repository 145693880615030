import { FormError, Input } from '@/components'
import { useCallback } from 'react'
import styles from '../UserPage.module.scss'

type CustomMaskInputProps = {
  setErrors: any
  fieldName: string
  isRequired: boolean
  mask: string
  placeholder: string
  error: string
  value: string
  onChange: any
  type?: 'date'
  icon?: JSX.Element
}

const CustomMaskInput = ({
  setErrors,
  fieldName,
  isRequired,
  mask,
  placeholder,
  error,
  value,
  onChange,
  type,
  icon,
}: CustomMaskInputProps) => {
  const onBlurMaskInput = useCallback(
    (field: string) => (e: any) => {
      if (e.target.value !== '' && e.target.value.includes('_')) {
        setErrors((prev: any) => {
          return {
            ...prev,
            [field]: field === 'phone' ? 'Неверный номер телефона' : 'Неверная дата',
          }
        })
      }
    },
    [],
  )

  const onFocusMaskInput = useCallback(
    (field: string) => () => {
      setErrors((prev: any) => {
        return { ...prev, [field]: null }
      })
    },
    [],
  )

  return (
    <FormError error={error} className={styles.inputError} key={fieldName}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={isRequired ? `${placeholder} (обязательно)` : placeholder}
        type={type}
        mask={mask}
        onBlur={onBlurMaskInput(fieldName)}
        onFocus={onFocusMaskInput(fieldName)}
        icon={icon}
      />
    </FormError>
  )
}

export default CustomMaskInput
