import * as React from 'react'
const Svg = () => (
  <svg
    width="245"
    height="181"
    viewBox="0 0 245 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_36_189"
      maskUnits="userSpaceOnUse"
      x="0"
      y="-56"
      width="293"
      height="293"
    >
      <g clip-path="url(#clip0_36_189)">
        <path
          d="M91.8382 192.015L244.002 152.503C264.283 147.237 271.882 134.599 266.691 114.609L239.905 11.4517C234.714 -8.53875 221.899 -15.9863 201.619 -10.7202L49.4547 28.7915C29.1744 34.0576 21.6017 46.7992 26.7925 66.7896L53.5788 169.947C58.7696 189.937 71.5538 197.282 91.8382 192.015ZM42.6468 63.6098C40.0256 53.5152 44.102 46.8658 53.8032 44.3467L205.483 4.96076C215.089 2.46642 221.886 6.29251 224.507 16.3871L226.623 24.5357L44.7627 71.7584L42.6468 63.6098ZM88.0736 176.326C78.3682 178.846 71.5995 175.11 68.9793 165.019L50.4825 93.7859L232.342 46.5632L250.838 117.793C253.458 127.883 249.358 134.441 239.752 136.936L88.0736 176.326ZM91.7719 144.697L115.551 138.523C121.277 137.036 124.079 132.269 122.643 126.737L117.981 108.784C116.57 103.351 111.777 100.45 106.051 101.937L82.2718 108.112C76.5455 109.599 73.7696 114.465 75.1802 119.897L79.842 137.851C81.2784 143.382 86.0455 146.184 91.7719 144.697Z"
          fill="white"
        />
      </g>
    </mask>
    <g mask="url(#mask0_36_189)">
      <rect x="1" y="-55" width="292" height="292" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_36_189">
        <rect
          width="239.559"
          height="239.559"
          fill="white"
          transform="translate(0.69043 4.81897) rotate(-14.5562)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default Svg
