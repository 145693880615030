const SVG = () => (
  <svg width={22} height={22} fill="none" viewBox="0 0 22 22">
    <path
      stroke="#585A61"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 15v-4m0-4h.01M21 11c0-5.523-4.477-10-10-10S1 5.477 1 11s4.477 10 10 10 10-4.477 10-10Z"
    />
  </svg>
)

export default SVG
