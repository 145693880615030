const getPlaceholder = (field: string) => {
  switch (field) {
    case 'full_name':
      return 'ФИО'
    case 'birthday':
      return 'Дата рождения'
    case 'car_brand':
      return 'Марка авто'
    case 'organization':
      return 'Организация'
    case 'car_color':
      return 'Цвет авто'
    case 'birthday':
      return 'Дата рождения'
    case 'address':
      return 'Адрес'
    case 'passport_number':
      return 'Серия и номер'
    case 'passport_date':
      return 'Дата выдачи'
    case 'passport_who_gave':
      return 'Кем выдан'
    default:
      return ''
  }
}

export default getPlaceholder
