import * as React from 'react'
const Svg = () => (
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="80" height="80" rx="17" fill="#3879F0" />
    <path
      d="M40.4994 16.667C27.6193 16.667 17.166 27.1203 17.166 40.0003C17.166 52.8803 27.6193 63.3337 40.4994 63.3337C53.3794 63.3337 63.8327 52.8803 63.8327 40.0003C63.8327 27.1203 53.3794 16.667 40.4994 16.667ZM40.4994 42.3337C39.216 42.3337 38.166 41.2837 38.166 40.0003V30.667C38.166 29.3837 39.216 28.3337 40.4994 28.3337C41.7827 28.3337 42.8327 29.3837 42.8327 30.667V40.0003C42.8327 41.2837 41.7827 42.3337 40.4994 42.3337ZM42.8327 51.667H38.166V47.0003H42.8327V51.667Z"
      fill="white"
    />
  </svg>
)
export default Svg
