import { CSSTransition } from 'react-transition-group'
import styles from './FormError.module.scss'
import cn from 'classnames'

export default function FormError({
  error,
  children,
  className,
}: {
  children: React.ReactNode
  error?: string
  className?: string
}) {
  const hasError = !!error

  return (
    <div className={styles.errorWrapper}>
      {children}
      <CSSTransition
        in={hasError}
        timeout={{
          appear: 0,
          enter: 160,
          exit: 160,
        }}
        classNames={{
          enter: styles['on-enter'],
          enterActive: styles['on-enter-active'],
          exit: styles['on-exit'],
          exitActive: styles['on-exit-active'],
        }}
        unmountOnExit
      >
        <div className={cn(styles.error, className)}>{error}</div>
      </CSSTransition>
    </div>
  )
}
