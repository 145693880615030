import { useLayoutEffect, useState } from 'react'
import { useRouter } from 'next/router'
import CarNumberPage from '../_common/CarNumberPage'
import getLinkHref from '@/helpers/getLinkHref'
import { setCookie } from 'cookies-next'
import { NUMBER_CAR } from '@/constants'
import { fetchStageByStrictSearch } from '@/store/slices/stagesSlice/stagesSlice'
import { StageByStrictSearchResponseData } from '@/store/slices/stagesSlice/interfaces'
import { useDispatch } from 'react-redux'
import { getFormattedCarNumber } from '@/helpers/getFormattedCarNumber'

export default function SubscriptionNumberPage({
  areaId,
  subscriptionId,
}: {
  areaId: string
  subscriptionId: string
}) {
  const dispatch = useDispatch()
  const router = useRouter()
  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  const onSubmit = async () => {
    const number = value.replace(/\s/g, '')
    if (number.length <= 2) return

    await dispatch(
      fetchStageByStrictSearch({
        number,
        areaId,
        onSuccess: (data: StageByStrictSearchResponseData | null) => {
          const paymentHref = getLinkHref({
            type: 'subscription-user',
            data: { areaId, subscriptionId, number, stageId: data?.id ?? null },
          })
          router.push(paymentHref)
          setError('')
          setCookie(NUMBER_CAR, number)
        },
      }),
    )
  }

  useLayoutEffect(() => {
    const number = getFormattedCarNumber()
    setValue(number)
  }, [])

  return (
    <CarNumberPage
      value={value}
      onChange={setValue}
      onSubmit={onSubmit}
      areaId={areaId}
      error={error}
      subscriptionId={subscriptionId}
      isSubscriptionPage
    />
  )
}
