import { Dispatch, SetStateAction } from 'react'
import { Button, Icon } from '@/components'
import styles from './Modal.module.scss'

export default function Modal({
  message,
  type = 'success',
  setIsOpen,
  onConfirm,
}: {
  message: string
  type: 'success' | 'warning'
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onConfirm?: () => void
}) {
  const closeModal = () => setIsOpen(false)

  return (
    <div className={styles.overlay} onClick={closeModal}>
      <div className={styles.modal} onClick={event => event.stopPropagation()}>
        <Icon name={`modal-${type}-icon`} />
        <p className={styles.paragraph}>{message}</p>
        <Button
          className={styles.button}
          onClick={() => {
            onConfirm && onConfirm()
            closeModal()
          }}
        >
          Хорошо
        </Button>
      </div>
    </div>
  )
}
