import { IconProps } from './interfaces'

const SVG = ({
  fill = 'currentColor',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m15 6-6 6 6 6"
      stroke={fill}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVG
