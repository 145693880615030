import { ReactNode } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import {format} from 'date-fns'
import ru from 'date-fns/locale/ru'
import { Title } from '@/components'
import { Area, IClientSubscription } from '@/store/slices/stagesSlice/interfaces'
import styles from './SubscriptionFieldsPage.module.scss'

const getTime = (initialDate: string) => {
  const date = new Date(initialDate)
  const day = format(date, 'dd.MM.yyyy', { locale: ru })
  const time = format(date, 'HH:mm', { locale: ru })
  return `${day}, ${time}`
}

export default function SubscriptionPageInfo({
  areaHref,
  areaInfo,
  clientSubscription,
}: {
  areaHref: string
  areaInfo: Area
  clientSubscription: IClientSubscription
}) {
  const { name, description, start_at, end_at, daily_start, daily_end, number } = clientSubscription

  return (
    <section className={styles.section}>
      <Title level={2} className={styles.sectionTitle}>
        Общие сведения
      </Title>
      <ul>
        <InfoListItem
          title="Название парковки"
          value={
            <span className={cn(styles.infoListItemText, styles.link)}>
              <Link href={areaHref}>{areaInfo.name}</Link>
            </span>
          }
        />
        <InfoListItem title="Адрес парковки" value={areaInfo.address} />
        <InfoListItem title="Название абонемента" value={name} />
        {description && <InfoListItem title="Что входит в абонемент" value={description} />}
        {start_at && <InfoListItem title="Начало действия" value={getTime(start_at)} />}
        {end_at && <InfoListItem title="Конец действия" value={getTime(end_at)} />}
        {daily_start && daily_end && (
          <InfoListItem
            title="Время действия внутри суток"
            value={`С ${daily_start} до ${daily_end}`}
          />
        )}
        <InfoListItem title="Номер авто" value={number} />
      </ul>
    </section>
  )
}

function InfoListItem({ title, value }: { title: string; value: string | ReactNode }) {
  return (
    <li className={styles.infoListItem}>
      <Title level={3} className={styles.infoListItemTitle}>
        {title}
      </Title>
      <p className={styles.infoListItemText}>{value}</p>
    </li>
  )
}
