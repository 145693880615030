import { Dispatch, SetStateAction, useEffect } from 'react'
import { PaymentStatus } from '../types'
import { PaymentReceiptField } from '@/store/slices/stagesSlice/interfaces'

type UsePaymentStatusArgs = {
  setPaymentStatus: Dispatch<SetStateAction<PaymentStatus>>
  error: { statusCode: number } | null
  receipt_link?: string | null
  ofd_url?: string | null
  fields?: PaymentReceiptField[]
}

export default function usePaymentStatus({
  setPaymentStatus,
  error,
  receipt_link,
  ofd_url,
  fields,
}: UsePaymentStatusArgs) {
  useEffect(() => {
    if (error?.statusCode === 404) {
      setPaymentStatus(PaymentStatus.PaymentConfirmation)
    }

    if (receipt_link === null && ofd_url === null && fields === null)
      setPaymentStatus(PaymentStatus.ReceiptGeneration)

    if (!!receipt_link) {
      setPaymentStatus(PaymentStatus.ReceiptIsReady)
      window.location.href = receipt_link
    }

    if (!!ofd_url && !!fields) {
      setPaymentStatus(PaymentStatus.ReceiptFields)
    }
  }, [error, receipt_link, ofd_url, fields])
}
