import { useClickOutside } from '@/helpers/hooks'
import { useState, useRef } from 'react'
import styles from './Select.module.scss'
import cn from 'classnames'
import Arrow from '@/assets/icons/Arrow'

interface SelectProps {
  options: { title: string; value: string }[]
  value: string
  onSelectChange: (value: string) => void
  placeholder: string
  isRequired?: boolean
}

const Select = ({ options, value, onSelectChange, placeholder, isRequired }: SelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef<HTMLDivElement | null>(null)

  const toggleSelect = () => {
    setIsOpen(!isOpen)
  }

  const onClickOutside = () => {
    setIsOpen(false)
  }

  useClickOutside(selectRef, onClickOutside)

  const chooseOption = (option: string) => () => {
    onSelectChange(option)
    toggleSelect()
  }

  return (
    <div ref={selectRef}>
      <div
        className={cn(styles.select, {
          [styles.select_isChooseValue]: !!value,
          [styles.select_isOpen]: isOpen,
        })}
        onClick={toggleSelect}
      >
        <span>
          {!!value
            ? options?.find(i => i.value === value)?.title
            : isRequired
            ? `${placeholder} (обязательно)`
            : placeholder}
        </span>
        <Arrow />
      </div>
      <div className={styles.option__wrapper}>
        <div className={cn(styles.option, { [styles.option_isOpen]: isOpen })}>
          <div className={styles.option__inner}>
            {options.map(option => (
              <div
                className={styles.option__item}
                onClick={chooseOption(option.value)}
                key={option.value}
              >
                {option.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Select
