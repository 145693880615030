import { Container, Title, Icon } from '@/components'
import styles from './PaymentResult.module.scss'
import cn from 'classnames'

export default function PaymentPendingPage() {
  return (
    <section className={styles.section}>
      <Container className={cn(styles.container, styles.container_isPendingPage)}>
        <div className={cn(styles.sign, styles.sign__Pending)}>
          <Icon name="spinner" />
        </div>
        <Title align="center">Проверяем статус оплаты...</Title>
      </Container>
    </section>
  )
}
