import React, { MouseEvent } from 'react'
import styles from './EmailDomainList.module.scss'

interface Props {
  list: string[]
  value: string
  onChange: (value: string) => void
}

const EmailDomainList = ({ list, value, onChange }: Props) => {
  const splittedValue = value.split('@')
  const domainToSearch = splittedValue?.[1] || null
  const valueWithoutDomain = splittedValue[0]
  const listToRender = domainToSearch
    ? list.filter(domain => domain.includes(domainToSearch) && domain !== `@${domainToSearch}`)
    : list

  const onClick = (e: MouseEvent<HTMLLIElement>) => {
    onChange(e.currentTarget.innerText)
  }

  return listToRender.length ? (
    <ul className={styles.list}>
      {listToRender.map(domain => {
        return (
          <li role="button" tabIndex={0} onClick={onClick} key={domain}>
            {valueWithoutDomain + domain}
          </li>
        )
      })}
    </ul>
  ) : (
    <></>
  )
}

export default EmailDomainList
