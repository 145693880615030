import { Title, Container, Input, Button, Paragraph, FormError } from '@/components'
import { changeUserEmail, getAreaInfoById } from '@/store/slices/stagesSlice/stagesSlice'
import { useState, useCallback, useEffect, useRef, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './EmailPage.module.scss'
import { useRouter } from 'next/router'
import { RootState } from '@/store'

export default function EmailPage({
  redirectUrl,
  required = true,
}: {
  redirectUrl: string
  required?: boolean
}) {
  const fieldRef = useRef(null)

  const dispatch = useDispatch()
  const router = useRouter()

  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: areaInfo } = getAreaInfoById(reduxState, router.query.areaId as string)

  useEffect(() => {
    if (error) {
      const field = (fieldRef.current as unknown) as HTMLInputElement
      field.focus()
    }
  }, [error])

  const onInputChange = useCallback(e => {
    setError('')
    setValue(e.target.value)
  }, [])

  const _onSubmit = async () => {
    const email = value.trim()

    if (required && !email.match(/.+[@].+/gm)) {
      setError('Укажите корректный email')
      return
    }

    await dispatch(changeUserEmail({ email }))
    router.push(redirectUrl)
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    _onSubmit()
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _onSubmit()
    }
  }

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div>
          <Title>Укажите ваш email</Title>

          <Paragraph>
            Ваш email необходим, чтобы мы могли прислать чек об успешной оплате.
          </Paragraph>

          <form onSubmit={onSubmit}>
            <label className={styles.input}>
              <span className={styles.label}>Email</span>
              <FormError error={error}>
                <Input
                  name="email"
                  value={value}
                  onChange={onInputChange}
                  ref={fieldRef}
                  inputMode="email"
                  onKeyPress={handleKeyPress}
                  danger={!!error}
                  placeholder="email@example.com"
                />
              </FormError>
            </label>
          </form>
        </div>

        <div className={styles.button}>
          <Button
            disabled={required && value.trim().length === 0}
            type="button"
            onClick={_onSubmit}
            background={areaInfo?.customising.button_back_color}
          >
            Продолжить
          </Button>
        </div>
      </Container>
    </section>
  )
}
