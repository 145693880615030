import { IconName } from '@/assets/icons/interfaces'

export enum CardType {
  PaymentCard = 'paymentCard',
  SubscriptionCard = 'subscriptionCard',
  HPCard = 'hpCard',
  BrendCard = 'brendCard',
}

interface IDefaultCardIconNames {
  [key: string]: IconName
}

interface ISlugPaymentCardIconNames {
  [key: string]: {
    [key: string]: IconName
  }
}

interface GetPaymentCardIconNameArgs {
  slug: string
  cardType: CardType
}

const defaultCardIconNames: IDefaultCardIconNames = {
  paymentCard: 'credit-card',
  subscriptionCard: 'credit-cards',
}

const areaCardIconNames: ISlugPaymentCardIconNames = {
  kashirskaya_plaza: {
    paymentCard: 'empty-credit-card',
    subscriptionCard: 'person-card',
  },
}

export const getCardIconName = ({ slug, cardType }: GetPaymentCardIconNameArgs): IconName => {
  if (slug in areaCardIconNames) {
    return areaCardIconNames[slug][cardType]
  }
  return defaultCardIconNames[cardType]
}
