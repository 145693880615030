import cn from 'classnames'
import styles from './FixedBottom.module.scss'
import Footer from '../Footer'

export default function FixedBottom({
  children,
  isHide,
  isDiscountPage,
}: {
  children: React.ReactNode
  isHide?: boolean
  isDiscountPage?: boolean
}) {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles['wrapper--hided']]: isHide,
      })}
    >
      <div className={styles.inner}>{children}</div>
      {isDiscountPage && <Footer />}
    </div>
  )
}
