import { Card, Icon } from '@/components'

import styles from './Card.module.scss'

const KashirskayaPlazaAppLinks = {
  ios:
    'https://apps.apple.com/ru/app/%D0%BA%D0%B0%D1%88%D0%B8%D1%80%D1%81%D0%BA%D0%B0%D1%8F-%D0%BF%D0%BB%D0%B0%D0%B7%D0%B0/id1479966513',
  playMarket: 'https://play.google.com/store/apps/details?id=com.mymall.kplaza',
}

export default function PaymentInKPlazaAppCard() {
  return (
    <Card className={styles.paymentApp}>
      <div className={styles.longContent}>
        <header>
          <h3 className={styles.subtitle}>
            Оплатить баллами или деньгами<br/>через приложение «Каширская Плаза»
          </h3>
        </header>
        <section className={styles.mobileApps}>
          <a href={KashirskayaPlazaAppLinks.ios} target="_blank" rel="noreferrer noopener">
            <Icon name="app-store-icon" />
          </a>
          <a href={KashirskayaPlazaAppLinks.playMarket} target="_blank" rel="noreferrer noopener">
            <Icon name="google-play-icon" />
          </a>
        </section>
      </div>
      <div className={styles.image}>
        <Icon name="kashirskaya-plaza-card" />
      </div>
    </Card>
  )
}
