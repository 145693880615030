import { useEffect, useState } from 'react'

const INITIAL_TIMER_COUNT = 59

export default function useTimer() {
  const [timer, setTimer] = useState<number>(INITIAL_TIMER_COUNT)

  useEffect(() => {
    setTimeout(() => timer && setTimer(prev => --prev), 1000)
  }, [timer])

  return {
    timer,
    displayTimer: `00:${timer < 10 ? '0' : ''}${timer}`,
  }
}
