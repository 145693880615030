import { useEffect, useState } from 'react'
import { PaymentStatus } from '@/components/pages/PaymentReceiptPage/types'

type UsePaymentStatusArgs = {
  paymentStatus: PaymentStatus
}

export default function usePaymentStatus({ paymentStatus }: UsePaymentStatusArgs) {
  const [activeStep, setActiveStep] = useState<1 | 2 | 3>(1)

  useEffect(() => {
    if (paymentStatus === PaymentStatus.PaymentConfirmation) {
      setActiveStep(1)
    }

    if (paymentStatus === PaymentStatus.ReceiptGeneration) {
      setActiveStep(2)
    }

    if (
      paymentStatus === PaymentStatus.ReceiptIsReady ||
      paymentStatus === PaymentStatus.ReceiptFields
    ) {
      setActiveStep(3)
    }
  }, [paymentStatus])

  return activeStep
}
