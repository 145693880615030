import { memo, forwardRef, useRef, useCallback, ChangeEventHandler } from 'react'
import { RadioProps } from './interfaces'
import styles from './Choice.module.scss'
import mergeRefs from 'react-merge-refs'
import cn from 'classnames'

const Radio = memo(
  forwardRef((props: RadioProps, inputRef) => {
    const { value, checked, onChange, disabled = false, title, name, className, ...rest } = props
    const ref = useRef<HTMLInputElement>(null)

    const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        onChange(e.target.value)
      },
      [onChange],
    )

    const onClick = () => {
      !disabled && onChange(value)
    }

    return (
      <div className={styles.radioInput__wrapper} onClick={onClick}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={_onChange}
          disabled={disabled}
          className={styles.radioInput}
          ref={mergeRefs([ref, inputRef])}
        />
        <label {...rest}></label>
        <span
          className={cn(styles.radioInput__title, className, {
            [styles.radioInput__title_isCheked]: checked,
          })}
        >
          {title}
        </span>
      </div>
    )
  }),
)

export default Radio
