import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { FormError, Input } from '@/components'
import { getLabelText } from '../utils'
import styles from '../SubscriptionFieldsPage.module.scss'
import { FormErrors } from '../SubscriptionPageForm'

type CustomMaskInputProps = {
  setErrors: Dispatch<SetStateAction<FormErrors>>
  fieldName: string
  isRequired: boolean
  mask: string
  error: string | null
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  danger: boolean
  isDisabled: boolean
  type?: 'date'
}

const CustomMaskInput = ({
  setErrors,
  fieldName,
  isRequired,
  mask,
  error,
  value,
  onChange,
  danger,
  type,
  isDisabled,
}: CustomMaskInputProps) => {
  const label = `${getLabelText(fieldName)} ${isRequired ? '*' : ''}`

  const onBlurMaskInput = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '' && e.target.value.includes('_')) {
      setErrors((prev: FormErrors) => {
        return {
          ...prev,
          [field]: field === 'phone' ? 'Неверный номер телефона' : 'Неверная дата',
        }
      })
    }
  }

  const onFocusMaskInput = (field: string) => () => {
    setErrors((prev: FormErrors) => {
      return { ...prev, [field]: null }
    })
  }

  return (
    <div className={styles.inputWrapper}>
      <FormError error={error ? error : undefined} className={styles.inputError} key={fieldName}>
        <span className={styles.label}>{label}</span>
        <Input
          className={styles.input}
          value={value}
          onChange={onChange}
          type={type}
          mask={mask}
          onBlur={onBlurMaskInput(fieldName)}
          onFocus={onFocusMaskInput(fieldName)}
          danger={danger}
          disabled={isDisabled}
        />
      </FormError>
    </div>
  )
}

export default CustomMaskInput
