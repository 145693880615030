import { RootState } from '@/store'
import { getDiscountAreaById } from '@/store/slices/stagesSlice/stagesSlice'
import { useSelector } from 'react-redux'
import styles from './Footer.module.scss'
import { useRouter } from 'next/router'

const Footer = () => {
  const { query } = useRouter()
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data } = getDiscountAreaById(reduxState, query.areaId as string)

  return (
    <footer className={styles.footer}>
      <div>
        Объект: <span>{data?.area?.name}</span>
      </div>
      <div>
        Арендатор:{' '}
        <span>
          {data?.renter?.activity_type.name} "{data?.renter?.name}"
        </span>
      </div>
    </footer>
  )
}

export default Footer
