import { FormError, Input } from '@/components'
import styles from '../UserPage.module.scss'
import { getPlaceholder } from '../utils'

type CustomInputProps = {
  fieldName: string
  onChange: any
  isRequired: boolean
  value: string
  error: string
  danger: boolean
}

const CustomInput = (props: CustomInputProps) => {
  const { fieldName, onChange, isRequired, value, error, danger } = props
  return (
    <FormError error={error} className={styles.inputError} key={fieldName}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={
          isRequired ? `${getPlaceholder(fieldName)} (обязательно)` : getPlaceholder(fieldName)
        }
        danger={danger}
      />
    </FormError>
  )
}

export default CustomInput
