export default function getLinkHref({ type, data }: Props) {
  let href = ''

  switch (type) {
    case 'area': {
      const { id } = data
      href = id ? `/${id}` : '/'
      break
    }
    case 'find-car-number': {
      const { id } = data
      href = `/${id}/find-car`
      break
    }
    case 'subscriptions': {
      const { areaId } = data
      href = `/${areaId}/subscriptions`
      break
    }
    case 'events': {
      const { areaId } = data
      href = `/${areaId}/events`
      break
    }
    case 'event-number': {
      const { areaId, eventId } = data
      href = `/${areaId}/events/${eventId}/number`
      break
    }
    case 'event-email': {
      const { areaId, eventId, number } = data
      href = `/${areaId}/events/${eventId}/email?number=${number}`
      break
    }
    case 'event-payment': {
      const { areaId, eventId, number } = data
      href = `/${areaId}/events/${eventId}/payment?number=${number}`
      break
    }
    case 'car-number-not-found': {
      const { id } = data
      href = `/${id}/not-found`
      break
    }
    case 'validate-car': {
      const { id, number } = data
      href = `/${id}/validate-car?number=${number}`
      break
    }
    case 'active-session': {
      const { areaId, stageId } = data
      href = `/${areaId}/active-session?stageId=${stageId}`
      break
    }
    case 'email-screen': {
      const { areaId, stageId } = data
      href = `/${areaId}/email?stageId=${stageId}`
      break
    }
    case 'payment': {
      const { areaId, stageId } = data
      href = `/${areaId}/payment?stageId=${stageId}`
      break
    }
    case 'apply-receipt-discount': {
      const { areaId, stageId } = data
      href = `/${areaId}/payment/apply-receipt-discount?stageId=${stageId}`
      break
    }
    case 'subscription-number': {
      const { areaId, subscriptionId } = data
      href = `/${areaId}/subscriptions/${subscriptionId}/number`
      break
    }
    case 'subscription-email': {
      const { areaId, subscriptionId, number } = data
      href = `/${areaId}/subscriptions/${subscriptionId}/email?number=${number}`
      break
    }
    case 'subscription-payment': {
      const { areaId, subscriptionId, number, stageId } = data
      href = `/${areaId}/subscriptions/${subscriptionId}/payment?number=${number}${stageId ? `&stageId=${stageId}` : ''}`
      break
    }
    case 'subscription-user': {
      const { areaId, subscriptionId, number, stageId } = data
      href = `/${areaId}/subscriptions/${subscriptionId}/user?number=${number}${stageId ? `&stageId=${stageId}` : ''}`
      break
    }
    case 'open-exit-gate': {
      const { stageId } = data
      href = `/open-exit-gate/${stageId}`
      break
    }
    default:
      break
  }

  return href
}

interface Props {
  type: LinkTypes
  data?: any
}

export type LinkTypes =
  | 'area'
  | 'find-car-number'
  | 'subscriptions'
  | 'car-number-not-found'
  | 'validate-car'
  | 'active-session'
  | 'email-screen'
  | 'payment'
  | 'subscription-number'
  | 'subscription-email'
  | 'subscription-payment'
  | 'open-exit-gate'
  | 'subscription-user'
  | 'events'
  | 'event-number'
  | 'event-email'
  | 'event-payment'
  | 'apply-receipt-discount'
