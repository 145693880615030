import { Title, Container, Paragraph, Button, FixedBottom, HalvaBanner } from '@/components'
import styles from './PaymentResult.module.scss'
import Link from 'next/link'

export default function PaymentSuccessByAppPage({
  paidUntil,
  area,
}: {
  paidUntil?: string | null
  area: {
    id: string
    phone?: string
    customising?: { button_back_color: string | null }
  } | null
}) {

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <HalvaBanner />
        <img
          className={styles.sign__Error__icon}
          src="/images/payment-success.svg"
          alt="logo"
          width={87}
          height={87}
        />

        <Title align="center">Парковка оплачена!</Title>

        <div className={styles.section__text_content}>
          <Paragraph align="center" className={styles.section__descr}>
            Вы можете выехать с парковки {paidUntil}.
          </Paragraph>
          <Paragraph align="center" className={styles.section__text}>
            Держите номер авто чистым для автоматического открытия шлагбаума.
          </Paragraph>
        </div>

        <FixedBottom>
          <div className={styles.button}>
            <Link href="hippoparking://app" passHref>
              <Button
                component="a"
                className={styles.button}
                background={area?.customising?.button_back_color}
              >
                На главную
              </Button>
            </Link>
          </div>
        </FixedBottom>
      </Container>
    </section>
  )
}
