import cn from 'classnames'
import { Icon, Title } from '@/components'
import { PaymentStatus } from '../types'
import styles from '../PaymentReceiptPage.module.scss'

export default function PaymentReceiptHeader({
  paymentStatus,
}: {
  paymentStatus: PaymentStatus
}) {
  const getTitle = () => {
    switch (paymentStatus) {
      case PaymentStatus.PaymentConfirmation:
        return (
          <>
            <span>Ожидание</span>
            подтверждения платежа
          </>
        )
      case PaymentStatus.ReceiptGeneration:
        return (
          <>
            <span>Ожидание</span>
            формирования чека
          </>
        )
      default:
        return (
          <>
            <span>Поздравляем,</span>
            ваш платеж подтвержден
          </>
        )
    }
  }

  return (
    <header
      className={cn(styles.header, {
        [styles.header_pending]:
          paymentStatus === PaymentStatus.PaymentConfirmation ||
          paymentStatus === PaymentStatus.ReceiptGeneration,
      })}
    >
      <div className={styles.header__top}>
        <div className={styles.header__logo}>
          <img className={styles.logoImage} src="/images/logo.png" alt="Логотип HippoParking" />
          <Title tag="span" className={styles.logoTitle}>
            HippoParking
          </Title>
        </div>
        <a href={'tel:88002003320'} className={styles.header__phone}>
          <Icon name="fill-phone-icon" />
        </a>
      </div>
      <Title className={styles.header__title}>{getTitle()}</Title>
    </header>
  )
}
