import { getAreaInfoById, getPaymentInfoById } from '@/store/slices/stagesSlice/stagesSlice'
import { Container, Button, Title, Card, Time } from '@/components'
import styles from './ActiveSessionPage.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import unicode from '@/helpers/unicodeChars'
import getLinkHref from '@/helpers/getLinkHref'
import formatNumberToString from '@/helpers/formatNumberToString'
// import AppLinks from '../_common/AppLinks'
import Link from 'next/link'
import cn from 'classnames'
import { getNumberLink } from '@/helpers/getNumberLink'

export default function ActiveSessionPage({
  stageId,
  areaId,
}: {
  stageId: string
  areaId: string
}) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data } = getPaymentInfoById(reduxState, stageId)
  const phoneLink = getNumberLink(data?.area?.phone)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId)

  const rates = data?.invoicing ?? []
  const time = data?.range ?? 0
  const totalPrice = data?.amount ?? '0'
  const areaName = data?.area?.name ?? ''
  const withZeroPrice = parseInt(totalPrice, 10) === 0
  const zeroPriceMinutes = rates[0]?.amount === 0 ? rates[0].roundingTo / 60 : null

  const areaHref = getLinkHref({ type: 'area', data: { id: areaId } })
  const emailScreenHref = getLinkHref({ type: 'email-screen', data: { areaId, stageId } })

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div>
          <Title>Активная парковка</Title>

          <Card color="decor" className={styles.card}>
            <div className={styles.card_Image}>
              <img src="/images/car-active-session.svg" alt="" />
            </div>

            <div className={styles.info}>
              {areaName && (
                <dl className={cn(styles.info_Item, styles.info_Item__Full)}>
                  <dt>Парковка</dt>
                  <dd>{areaName}</dd>
                </dl>
              )}
              <dl className={cn(styles.info_Item, styles.info_Item__Bold)}>
                <dt>Время на парковке</dt>
                <dd>
                  <Time timeInSeconds={time} />
                  {withZeroPrice && zeroPriceMinutes && (
                    <small className={styles.info_Tip}>
                      Первые {zeroPriceMinutes} мин бесплатно
                    </small>
                  )}
                </dd>
              </dl>
              <dl className={cn(styles.info_Item, styles.info_Item__Bold)}>
                <dt>Стоимость</dt>
                <dd>
                  {formatNumberToString(+totalPrice)}
                  {unicode.no_break_space}
                  {unicode.ruble}
                </dd>
              </dl>
            </div>
          </Card>
        </div>
        <div className={styles.buttons}>
          {withZeroPrice ? (
            <Link href={areaHref} passHref>
              <Button component="a" className={styles.button} withLoader>
                Ок
              </Button>
            </Link>
          ) : (
            <Link href={emailScreenHref} passHref>
              <Button
                component="a"
                withLoader
                className={styles.button}
                background={areaInfo?.customising.button_back_color}
              >
                Продолжить
              </Button>
            </Link>
          )}
          {phoneLink && (
            <Button component="a" href={phoneLink} className={styles.button} isTransparent>
              Обратиться в поддержку
            </Button>
          )}
        </div>

        {/* <AppLinks /> */}
      </Container>
    </section>
  )
}
