import styles from '../UserPage.module.scss'
import { passportFields } from '../config'
import CustomInput from './CustomInput'
import CustomMaskInput from './CustomMaskInput'

const PassportBlock = ({
  onChange,
  isRequired,
  values,
  errors,
  setErrors,
}: {
  onChange: any
  isRequired: boolean
  values: any
  errors: any
  setErrors: any
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>Паспортные данные</div>
      <div className={styles.passport}>
        {passportFields.map(nameField => {
          switch (nameField) {
            case 'passport_date':
              return (
                <CustomMaskInput
                  setErrors={setErrors}
                  fieldName={nameField}
                  isRequired={isRequired}
                  mask={'99/99/9999'}
                  placeholder={'Дата выдачи'}
                  error={errors[nameField]}
                  value={values[nameField]}
                  onChange={onChange(nameField)}
                  type={'date'}
                  key={nameField}
                />
              )
            default:
              return (
                <CustomInput
                  fieldName={nameField}
                  onChange={onChange(nameField)}
                  isRequired={isRequired}
                  value={values[nameField]}
                  error={errors[nameField]}
                  danger={!!values[nameField]?.value}
                  key={nameField}
                />
              )
          }
        })}
      </div>
    </div>
  )
}

export default PassportBlock
