import { Children, cloneElement, isValidElement } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import cn from 'classnames'
import qs from 'qs'

type ActiveLinkProps = {
  href: ActiveLinkHref
  shallow?: boolean
  activeClass: string
  children: JSX.Element
  [x: string]: any
}

type ActiveLinkHref =
  | {
      pathname: string
      query?: {
        [key: string]: any
      }
    }
  | string

export default function ActiveLink({
  href,
  children,
  shallow = false,
  activeClass,
  ...props
}: ActiveLinkProps) {
  const router = useRouter()

  let hrefPathname
  let queriesStringified
  if (typeof href === 'object') {
    const query = href.query ?? null
    hrefPathname = href.pathname
    queriesStringified = qs.stringify(query, {
      encode: false,
    })
  } else {
    ;[hrefPathname, queriesStringified] = href.split('?')
  }

  const isActive = router.asPath.split('?')[0] === hrefPathname
  const componentProps = isActive ? { active: true, ...props } : { ...props }

  let elements = Children.toArray(children)
  const component = isValidElement(elements[0]) ? cloneElement(elements[0], componentProps) : null
  const componentClassName = component?.props?.className

  if (isActive) {
    return typeof component?.type === 'string' ? (
      <span className={componentClassName ? cn(componentClassName, activeClass) : activeClass}>
        {component.props.children}
      </span>
    ) : (
      <>{component}</>
    )
  } else {
    return (
      <Link
        href={queriesStringified ? `${hrefPathname}?${queriesStringified}` : hrefPathname}
        shallow={shallow}
        passHref
      >
        {component}
      </Link>
    )
  }
}
