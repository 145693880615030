import { IconProps } from './interfaces'

const SVG = ({ fill = '#548DF4' }: IconProps) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" fill="white" />
    <path
      d="M46.8322 39.3801V43.8801C46.8339 44.2979 46.7483 44.7114 46.5809 45.0942C46.4136 45.4769 46.1681 45.8205 45.8603 46.1029C45.5525 46.3854 45.189 46.6004 44.7933 46.7342C44.3976 46.868 43.9782 46.9177 43.5622 46.8801C38.9464 46.3786 34.5127 44.8014 30.6172 42.2751C26.9929 39.9721 23.9202 36.8994 21.6172 33.2751C19.0821 29.362 17.5045 24.9066 17.0122 20.2701C16.9747 19.8553 17.024 19.4373 17.1569 19.0426C17.2899 18.6479 17.5035 18.2852 17.7843 17.9776C18.0651 17.67 18.4069 17.4242 18.7879 17.2559C19.1688 17.0876 19.5807 17.0005 19.9972 17.0001H24.4972C25.2251 16.993 25.9309 17.2508 26.4828 17.7254C27.0348 18.2001 27.3953 18.8593 27.4972 19.5801C27.6871 21.0202 28.0394 22.4342 28.5472 23.7951C28.749 24.332 28.7927 24.9155 28.673 25.4765C28.5534 26.0374 28.2755 26.5523 27.8722 26.9601L25.9672 28.8651C28.1025 32.6205 31.2119 35.7298 34.9672 37.8651L36.8722 35.9601C37.28 35.5569 37.7949 35.2789 38.3559 35.1593C38.9168 35.0397 39.5003 35.0833 40.0372 35.2851C41.3981 35.793 42.8121 36.1452 44.2522 36.3351C44.9808 36.4379 45.6463 36.805 46.122 37.3664C46.5977 37.9278 46.8504 38.6445 46.8322 39.3801Z"
      fill={fill}
    />
  </svg>
)

export default SVG
