import { Radio } from '@/components'
import styles from '../UserPage.module.scss'



const RadioList = ({
  data,
  title,
  onChange,
  currentValue,
}: {
  data: Array<{ [key: string]: string }>
  title: string
  onChange: any
  currentValue: string
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.gender__label}>{title}</div>
      <div className={styles.gender}>
        {data?.map(({ name, value, title }) => (
          <Radio
            key={value}
            name={name}
            value={value}
            checked={currentValue === value}
            title={title}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioList
