import styles from '../PaymentReceiptPage.module.scss'

export default function PaymentReceiptLoading() {
  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <main className={styles.loadingPage}>
          <div className={styles.loader}></div>
        </main>
      </div>
    </div>
  )
}
