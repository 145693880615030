import { configureStore, StateFromReducersMapObject, ThunkAction, Action } from '@reduxjs/toolkit'
import stagesReducer from './slices/stagesSlice/stagesSlice'
import getConfig from 'next/config'
import { useMemo } from 'react'
import Api from '@/api'

const { publicRuntimeConfig } = getConfig()

const reducer = {
  stages: stagesReducer,
}

type InitStoreConfig = {
  baseUrl: string
}

export function initStore(
  preloadedState: Partial<RootState> | undefined,
  { baseUrl }: InitStoreConfig,
) {
  const api = new Api(baseUrl)

  const store = configureStore({
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { api },
        },
        immutableCheck: false,
        serializableCheck: false,
      }),
  })

  return store
}

export const initializeStore = (() => {
  let store: Store

  return (initialState?: RootState) => {
    if (typeof window === 'undefined') {
      return initStore(initialState, { baseUrl: publicRuntimeConfig?.apiUrl })
    }

    if (!store) {
      store = initStore(initialState, { baseUrl: publicRuntimeConfig?.browserApiUrl })
    }

    return store
  }
})()

export function useStore(initialState: RootState): Store {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}

export type RootState = StateFromReducersMapObject<typeof reducer>
export type Store = ReturnType<typeof initStore>
export type AppDispatch = Store['dispatch']
export type AppGetState = Store['getState']
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { api: Api },
  Action<string>
>
